<script setup lang="ts">
    import LoadingSpinner from '@/components/LoadingSpinner.vue';
    import ProductListItem from '@/product/common/components/ProductListItem.vue';
    import Button from 'primevue/button';
    import { useCartAction, useCartQuery } from '@/composables/cart';
    import { useProductAction, useProductQuery } from '@/composables/product';
    import { ProductFetchType } from '@/stores/product';
    import type { CtxProduct } from '@containex/portal-backend-api-client';
    import { useAsyncTask } from 'vue-concurrency';
    import { useMarketQuery } from '@/composables/market';
    import { computed, ref, watch } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { useOrderInfoSidebarAction } from '@/composables/order-info-sidebar';
    import { isMobile } from '@/util/breakpoints';
    import ProductFilter from '@/product/common/components/ProductFilter.vue';
    import { useProvisionTypeQuery } from '@/composables/provision-type';
    import { isEqual } from 'date-fns';

    const { products, totalCount, currentFacets } = useProductQuery();
    const productAction = useProductAction();
    const cartAction = useCartAction();
    const { market } = useMarketQuery();
    const { isChangingProvisionType } = useProvisionTypeQuery();
    const { rentalStart, rentalEnd, currentRegionId, currentZipCode } = useCartQuery();
    const orderInfoSidebarAction = useOrderInfoSidebarAction();

    const { t } = useI18n();

    const isRentalDurationSet = computed(() => rentalStart.value != null && rentalEnd.value != null);

    const fetchProductsTask = useAsyncTask(async (signal, fetchType: ProductFetchType) => {
        await productAction.fetchProducts(fetchType);
    }).drop();
    void fetchProductsTask.perform(ProductFetchType.RESET_PRODUCTS);

    async function handleAddToCart(productToAdd: CtxProduct, variantIdToAdd: string): Promise<void> {
        if (rentalStart.value == null || rentalEnd.value == null) {
            orderInfoSidebarAction.setProductToAdd(productToAdd);
            orderInfoSidebarAction.setIsVisible(true);
        } else {
            await cartAction.addLineItemByVariantId(productToAdd, variantIdToAdd);
        }
    }

    // Reload products when switching region (different prices)
    watch(
        [rentalEnd],
        (newRentalEnd, oldRentalEnd) => {
            if (oldRentalEnd[0] != null && newRentalEnd[0] != null && isEqual(oldRentalEnd[0], newRentalEnd[0])) {
                return;
            }

            if (currentRegionId.value != null) {
                void fetchProductsTask.perform(ProductFetchType.RESET_PRODUCTS);
            }
        },
        { immediate: true }
    );

    watch(
        [rentalStart],
        (newRentalStart, oldRentalStart) => {
            if (
                oldRentalStart[0] != null &&
                newRentalStart[0] != null &&
                isEqual(oldRentalStart[0], newRentalStart[0])
            ) {
                return;
            }

            if (currentRegionId.value != null) {
                void fetchProductsTask.perform(ProductFetchType.RESET_PRODUCTS);
            }
        },
        { immediate: true }
    );

    watch(
        [currentRegionId],
        () => {
            if (currentRegionId.value != null) {
                void fetchProductsTask.perform(ProductFetchType.RESET_PRODUCTS);
            }
        },
        { immediate: true }
    );

    const showFilterDialog = ref(false);

    async function updateFacets(facets: string[]): Promise<void> {
        showFilterDialog.value = false;
        productAction.setCurrentFacets(facets);
        await fetchProductsTask.perform(ProductFetchType.RESET_PRODUCTS);
    }
</script>

<template>
    <h2 class="product-list-header text-4xl-bold-line-height-auto">{{ t('PRODUCT.ALL_PRODUCTS') }}</h2>
    <div v-if="isMobile" class="mobile-buttons">
        <Button
            outlined
            class="mobile-filter-button text-base-semibold-line-height-auto"
            @click="showFilterDialog = true"
        >
            <i class="pi pi-filter"></i>{{ t('PRODUCT.FILTER_BUTTON') }}
        </Button>
    </div>
    <div v-if="!isChangingProvisionType" class="product-list-wrapper">
        <!-- ToDo: CPP-1199: set the actual pre-selected facets -->
        <ProductFilter
            :pre-selected-facets="[]"
            :active-facets="currentFacets"
            :show-filter-dialog="showFilterDialog"
            @update:active-facets="updateFacets"
            @close="showFilterDialog = false"
        ></ProductFilter>
        <div v-if="market != null" class="product-list-container">
            <div class="product-list">
                <ProductListItem
                    v-for="(product, index) in products"
                    :key="product.id"
                    :data-testid="`product-list-item-${index}`"
                    :product="product"
                    :current-region-id="currentRegionId"
                    :postal-code="currentZipCode"
                    :is-rental="true"
                    :is-rental-duration-set="isRentalDurationSet"
                    :market="market"
                    :is-search-result="false"
                    @add-to-cart="handleAddToCart"
                    @show-price="orderInfoSidebarAction.setIsVisible(true)"
                />
            </div>
            <div class="show-more">
                <Button
                    v-if="products.length < totalCount && !fetchProductsTask.isRunning"
                    id="show-more-button"
                    outlined
                    class="text-base-bold-line-height-auto"
                    :disabled="fetchProductsTask.isRunning"
                    data-testid="product-list-show-more"
                    @click="fetchProductsTask.perform(ProductFetchType.LOAD_ADDITIONAL_PRODUCTS)"
                    >{{ t('PRODUCT.SHOW_MORE_PRODUCTS') }}
                </Button>
                <LoadingSpinner v-else-if="fetchProductsTask.isRunning"></LoadingSpinner>
                <div v-if="products.length > 0 || !fetchProductsTask.isRunning" class="product-count">
                    {{ t('PRODUCT.X_OF_Y_PRODUCTS', { x: products.length, y: totalCount }) }}
                </div>
            </div>
        </div>
    </div>
    <div v-else class="center">
        <LoadingSpinner></LoadingSpinner>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .product-list-header {
        padding-bottom: main.$spacing-6;
    }

    .product-list-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        gap: main.$spacing-6;
    }

    @include main.for-breakpoint-lg {
        .product-list-wrapper {
            grid-template-columns: 1fr 3fr;
        }
    }

    .product-list-container {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-5;
    }

    .center {
        display: flex;
        justify-content: center;
    }

    .product-list {
        display: grid;
        grid-template-columns: 100%;
        grid-gap: main.$spacing-5;
    }

    @include main.for-breakpoint-sm {
        .product-list {
            grid-template-columns: 1fr 1fr;
        }
    }

    @include main.for-breakpoint-md {
        .product-list {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    .show-more {
        text-align: center;
        padding-top: main.$spacing-6;
    }

    .product-count {
        padding-top: main.$spacing-2;
        color: main.$color-secondary;
    }

    .mobile-buttons {
        display: flex;
        gap: main.$spacing-5;
        padding-bottom: main.$spacing-6;
    }

    .mobile-filter-button {
        gap: main.$spacing-3;
    }

    #show-more-button {
        &:hover {
            background-color: main.$color-background-bluegray;
        }
    }
</style>
