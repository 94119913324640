<script setup lang="ts">
    import { Navigation } from '@/router/navigation';

    const props = defineProps<{
        back: {
            title: string;
            link: Navigation;
        }[];
        title: string;
    }>();
</script>
<template>
    <div class="breadcrumbs text-base-regular-line-height-auto">
        <template v-for="item in props.back" :key="item.title">
            <router-link :to="{ name: item.link }">
                {{ item.title }}
            </router-link>
            <span class="pi pi-chevron-right"></span>
        </template>
        {{ props.title }}
    </div>
</template>
<style scoped lang="scss">
    @use 'src/styling/main';

    .breadcrumbs {
        padding-bottom: main.$spacing-6;
        display: flex;
        flex-direction: row;
        gap: main.$spacing-2;
        align-items: center;
        color: main.$color-secondary;
    }

    .breadcrumbs a {
        text-decoration: none;
        color: main.$color-text;
    }

    .breadcrumbs a:hover {
        color: main.$color-text;
    }
</style>
