<script setup lang="ts">
    import { useProductAction } from '@/composables/product';
    import type { CtxProduct } from '@containex/portal-backend-api-client';
    import { useDebounceFn } from '@vueuse/core';
    import IconField from 'primevue/iconfield';
    import InputIcon from 'primevue/inputicon';
    import InputText from 'primevue/inputtext';
    import Popover from 'primevue/popover';
    import { ref, useTemplateRef, type Ref } from 'vue';
    import { useMarketQuery } from '@/composables/market';
    import { useCartQuery } from '@/composables/cart';
    import ProductListItem from '@/product/common/components/ProductListItem.vue';
    import { isMobile } from '@/util/breakpoints';
    import { useI18n } from 'vue-i18n';

    type PopoverType = InstanceType<typeof Popover>;
    type InputTextType = InstanceType<typeof InputText>;

    const searchValue = ref('');
    const product: Ref<CtxProduct | null> = ref(null);

    const productSearchPopover = useTemplateRef<PopoverType>('productSearchPopover');
    const productSearchInput = useTemplateRef<InputTextType>('productSearchInput');

    const { t } = useI18n();
    const productAction = useProductAction();
    const { currentRegionId, currentZipCode } = useCartQuery();
    const { market } = useMarketQuery();

    const debounceTimeInMs = 200;
    const debounceSearchValue = useDebounceFn(async (value: string) => {
        product.value = await productAction.searchProductByArticleId(value);
        // @ts-expect-error this property does exist and we know it.
        productSearchPopover.value?.show(new MouseEvent('click'), productSearchInput.value?.$el);
    }, debounceTimeInMs);

    async function searchValueChange(value: string | undefined): Promise<void> {
        if (value == null || value.length === 0) {
            productSearchPopover.value?.hide();
        } else {
            await debounceSearchValue(value);
        }
    }

    async function onFocus(payload: FocusEvent): Promise<void> {
        await searchValueChange(searchValue.value);
    }
</script>
<template>
    <div class="search-box full-width" style="width: 100%">
        <IconField icon-position="left" :class="{ 'full-width': isMobile }">
            <InputIcon class="pi pi-search"> </InputIcon>
            <InputText
                ref="productSearchInput"
                v-model="searchValue"
                class="text-base-regular-line-height-auto"
                :class="{ 'full-width': isMobile }"
                :placeholder="t('PRODUCT.SEARCH.ARTICLE_ID')"
                data-testid="product-search-input"
                size="large"
                @update:model-value="searchValueChange"
                @focus="onFocus"
            />
        </IconField>
        <Popover ref="productSearchPopover">
            <div v-if="product == null">
                <center><i class="pi pi-search"></i></center>
                <p class="not-found-text" data-testid="product-search-failed">
                    {{ t('PRODUCT.SEARCH.NO_PRODUCTS_FOUND', { articleId: searchValue }) }}
                </p>
            </div>
            <ProductListItem
                v-else-if="market != null"
                :key="product.id"
                :product="product"
                :current-region-id="currentRegionId"
                :postal-code="currentZipCode"
                :is-rental="false"
                :is-rental-duration-set="false"
                :market="market"
                :is-search-result="true"
                data-testid="product-search-result"
            />
        </Popover>
    </div>
</template>
<style scoped lang="scss">
    @use 'src/styling/main';

    .search-box {
        display: flex;
        gap: main.$spacing-4;

        :deep(.p-inputtext) {
            height: 2.5rem;
        }
    }

    .not-found-text {
        margin: 0;
        padding-top: main.$spacing-2;
    }
</style>
