<script setup lang="ts">
    import { useCartQuery } from '@/composables/cart';
    import CartItem from '@/cart/components/CartItem.vue';
    import CartTotal from '@/cart/components/CartTotal.vue';
    import router from '@/router';
    import { Navigation } from '@/router/navigation';
    import Button from 'primevue/button';
    import { useI18n } from 'vue-i18n';
    import { type App, computed, inject } from 'vue';
    import AdditionalProductSidebar from '@/cart/components/AdditionalProductSidebar.vue';
    import { createComponent } from '@/util/create-component';
    import { partition } from '@containex/common-utils';
    import { CONTAINER_ARTICLE_TYPES } from '@containex/portal-business-logic';
    import { lineItemsWithoutChilds, sortLineItemsByCreationDate } from '@/util/line-items';
    import { useOrderInfoSidebarAction } from '@/composables/order-info-sidebar';
    import { useCheckoutCartAction } from '@/checkout/composables/checkout-cart';

    const { t } = useI18n();
    const { cart, lineItemsQuantityCount, rentalStart, rentalEnd } = useCartQuery();
    const app = inject<App>('ctxApp');
    const orderInfoSidebarAction = useOrderInfoSidebarAction();
    let sidebar: ReturnType<typeof createComponent> | undefined;

    const checkoutCartAction = useCheckoutCartAction();

    checkoutCartAction.resetCheckoutCart();
    if (cart.value != null) {
        checkoutCartAction.initCheckoutCart(cart.value);
    }

    const lineItems = computed(() => {
        const items = cart.value?.items ?? [];
        const lineItemsWithoutSpecialCosts = lineItemsWithoutChilds(items);
        const sortedLineItems = sortLineItemsByCreationDate(lineItemsWithoutSpecialCosts);

        const groupedLineItems = partition(sortedLineItems, (item) =>
            CONTAINER_ARTICLE_TYPES.includes(item.variant?.product?.article_type ?? '')
        );

        return {
            productLineItems: groupedLineItems[0],
            additionalProducts: groupedLineItems[1],
        };
    });

    const isRentalDurationSet = computed(() => rentalStart.value != null && rentalEnd.value != null);

    function addAdditionalProducts(): void {
        if (app == null) {
            throw new Error('App is not provided for injection');
        }

        const element = document.createElement('div');
        document.body.appendChild(element);

        sidebar = createComponent(app, AdditionalProductSidebar, element, {
            onClose() {
                sidebar?.destroy();
                document.body.removeChild(element);
            },
        });
    }

    function showOrderInfoSidebar(): void {
        orderInfoSidebarAction.setIsVisible(true);
    }
</script>

<template>
    <template v-if="cart != null && lineItemsQuantityCount > 0">
        <h2 class="cart-header text-4xl-bold-line-height-auto">{{ t('CART.CART') }}</h2>

        <div class="cart-container">
            <div class="cart-main-part">
                <h3 class="text-xl-bold-line-height-auto">{{ t('CART.CONTAINER_HEADLINE') }}</h3>
                <div class="cart-item-list">
                    <CartItem
                        v-for="lineItem in lineItems.productLineItems"
                        :key="lineItem.id"
                        :line-item="lineItem"
                        :discount="null"
                        :is-rental="true"
                    />
                </div>

                <br />

                <h3 class="text-xl-bold-line-height-auto">{{ t('CART.ADDITIONAL_PRODUCTS.TITLE') }}</h3>
                <div class="cart-item-list">
                    <span v-if="lineItems.additionalProducts.length === 0">{{
                        t('CART.ADDITIONAL_PRODUCTS.EMPTY_TEXT')
                    }}</span>
                    <CartItem
                        v-for="lineItem in lineItems.additionalProducts"
                        :key="lineItem.id"
                        :line-item="lineItem"
                        :discount="null"
                        :is-rental="true"
                    />
                    <Button
                        outlined
                        class="add-additional-product-button"
                        :label="t('CART.ADDITIONAL_PRODUCTS.ADD_BUTTON_TEXT')"
                        icon="pi pi-plus"
                        @click="addAdditionalProducts"
                    />
                </div>
            </div>
            <div class="cart-sidebar">
                <div>
                    <template v-if="isRentalDurationSet">
                        <CartTotal :cart="cart" :show-checkout-button="true" :is-rental="true" />
                    </template>
                    <template v-else>
                        <div class="no-rental-duration-container">
                            <span class="text-xl-bold-line-height-auto"> {{ t('CART.SET_RENTAL_DURATION') }}</span>
                            <span class="no-rental-duration-info-text">{{ t('CART.SET_RENTAL_DURATION_INFO') }}</span>
                            <Button
                                icon="pi pi-calendar"
                                class="no-rental-duration-button"
                                :label="t('CART.SET_RENTAL_DURATION')"
                                @click="showOrderInfoSidebar"
                            />
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </template>

    <template v-else>
        <div class="empty-cart-container">
            <span class="pi pi-shopping-cart icon" />
            <h2 class="heading">{{ t('CART.CART_EMPTY') }}</h2>
            <p>{{ t('CART.CART_EMPTY_TEXT') }}</p>
            <Button
                icon="pi pi-percentage"
                :label="t('NOT_FOUND.CTA_BUTTON')"
                @click="router.push({ name: Navigation.RentalProductList })"
            />
        </div>
    </template>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .cart-header {
        padding-bottom: main.$spacing-6;
    }

    .cart-container {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-5;
    }

    .cart-main-part {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: main.$spacing-5;
    }

    .cart-sidebar {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: main.$spacing-6;
    }

    @include main.for-breakpoint-lg {
        .cart-container {
            flex-direction: row;
        }

        .cart-sidebar {
            min-width: 384px;
        }
    }

    .cart-item-list {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-5;
    }

    .add-additional-product-button {
        align-self: start;
    }

    .cart-delivery-container {
        background: main.$color-background-bluegray;
        padding: main.$spacing-4;
    }

    .cart-delivery-text {
        padding-top: main.$spacing-3;
        padding-bottom: main.$spacing-3;
    }

    .empty-cart-container {
        margin: 0 auto;
        background: main.$color-white;
        padding: main.$spacing-6;
        text-align: center;
        max-width: 588px;
    }

    .heading {
        font-weight: 700;
    }

    .icon {
        font-size: 36px;
        color: main.$color-secondary;
        padding-bottom: main.$spacing-6;
    }

    .no-rental-duration-container {
        display: flex;
        flex-direction: column;
        background: main.$color-background-lightblue;
        padding: main.$spacing-5;
    }

    .no-rental-duration-info-text {
        padding-top: main.$spacing-4;
        padding-bottom: main.$spacing-4;
    }

    .no-rental-duration-button {
        align-self: start;
    }
</style>
