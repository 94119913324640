<script setup lang="ts">
    import { useI18n } from 'vue-i18n';

    defineProps<{
        stockQuantity: number;
    }>();

    const { t } = useI18n();
</script>

<template>
    <div class="icon-with-text-container">
        <i class="pi pi-box" />
        {{
            t('PRODUCT.X_QUANTITY_IN_STOCK', {
                stockQuantity,
            })
        }}
    </div>
</template>
