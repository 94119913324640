<script setup lang="ts">
    import { computed } from 'vue';
    import { useI18n } from 'vue-i18n';
    import Button from 'primevue/button';
    import router from '@/router';
    import { buttonFontWeight500, buttonFontWeight700 } from '@/styling/components/button';
    import { useCartQuery } from '@/composables/cart';
    import { getComputedCartUrlByProvisionType } from '@/util/router';

    const props = withDefaults(
        defineProps<{
            variantId: string | undefined;
            isAddToCartDisabled: boolean;
            mode?: 'default' | 'icon-only' | 'row-reverse';
        }>(),
        {
            mode: 'default',
        }
    );

    const emits = defineEmits<{
        addToCart: [];
    }>();

    const { t } = useI18n();
    const cartUrl = getComputedCartUrlByProvisionType();
    const { lineItemsQuantityLookupRecord } = useCartQuery();

    const lineItemQuantityLookupRecord = computed(() => {
        if (props.variantId != null && lineItemsQuantityLookupRecord.value != null) {
            return lineItemsQuantityLookupRecord.value[props.variantId];
        }
        return undefined;
    });

    const isQuantityAvailableReached = computed(
        () =>
            lineItemQuantityLookupRecord.value != null &&
            lineItemQuantityLookupRecord.value.quantityLimit == null &&
            lineItemQuantityLookupRecord.value.inventoryQuantity <= lineItemQuantityLookupRecord.value.quantity
    );

    const isQuantityLimitReached = computed(
        () =>
            lineItemQuantityLookupRecord.value?.quantityLimit != null &&
            lineItemQuantityLookupRecord.value.quantityLimit <= lineItemQuantityLookupRecord.value.quantity
    );
</script>

<template>
    <div
        class="product-cart-button-group-container"
        :class="{
            'product-cart-button-group-container--row-reverse':
                mode === 'row-reverse' && lineItemQuantityLookupRecord != null,
        }"
    >
        <Button
            v-if="lineItemQuantityLookupRecord != null"
            icon="pi pi-shopping-cart"
            icon-pos="left"
            outlined
            severity="secondary"
            class="cart-button"
            :label="`${lineItemQuantityLookupRecord.quantity}`"
            :dt="buttonFontWeight500"
            @click.prevent="router.push({ name: cartUrl })"
        />
        <Button
            v-if="mode !== 'icon-only' && (isQuantityAvailableReached || isQuantityLimitReached)"
            disabled
            :label="
                isQuantityAvailableReached ? t('CART.QUANTITY_AVAILABLE_REACHED') : t('CART.QUANTITY_LIMIT_REACHED')
            "
            class="cta-button"
            :class="{ 'full-width': mode !== 'row-reverse' }"
            :dt="buttonFontWeight700"
        />
        <Button
            v-else
            icon="pi pi-cart-plus"
            icon-pos="left"
            :disabled="isAddToCartDisabled"
            :label="mode === 'icon-only' ? undefined : t('CART.ADD_TO_CART')"
            class="cta-button"
            :class="{ 'full-width': mode !== 'row-reverse', 'cta-button--icon-only': mode === 'icon-only' }"
            :dt="buttonFontWeight700"
            data-testid="product-cart-button-group-add-to-cart-button"
            @click.prevent="emits('addToCart')"
        />
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .product-cart-button-group-container {
        display: flex;
        gap: main.$spacing-3;

        .p-button > :deep(.p-button-icon) {
            font-size: main.$font-size-2;
        }

        &--row-reverse {
            flex-direction: row-reverse;
            justify-content: flex-end;
        }
    }

    .cart-button {
        border-color: main.$color-product-card-cart-button-border;
        padding: main.$spacing-3;
        color: inherit;
        gap: 6px;
        flex-shrink: 0;
    }

    .cta-button {
        display: flex;
        font-size: 14px;

        &--icon-only {
            flex-shrink: 0;
            padding: main.$spacing-3 main.$spacing-5;
            width: fit-content;
        }
    }
</style>
