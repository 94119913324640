<script setup lang="ts">
    import { computed } from 'vue';
    import { useI18n } from 'vue-i18n';

    const props = defineProps<{
        documentsUrl: string;
    }>();

    const { t } = useI18n();

    const documentUrl = computed(() => `https://doc.containex.com/?encryptedFolder=${props.documentsUrl}`);
</script>
<template>
    <div class="documents">
        <h2 class="title text-2xl-bold-line-height-auto">{{ t('PRODUCT.DOCUMENTS') }}</h2>
        <div class="external-link-container">
            <span class="pi pi-external-link"></span>
            <a
                :href="documentUrl"
                class="external-link"
                target="_blank"
                data-testid="product-detail-view-documents-link"
            >
                {{ t('PRODUCT.SHOW_DOCUMENTS_PAGE') }}
            </a>
        </div>
    </div>
</template>
<style scoped lang="scss">
    @use 'src/styling/main';

    .documents {
        max-width: 792px;
        width: 100%;
    }

    .title {
        padding-left: main.$spacing-5;
    }

    @include main.for-breakpoint-sm {
        .title {
            padding-left: 0;
        }
    }

    .external-link-container {
        display: flex;
        flex-direction: row;
        gap: main.$spacing-2;
        align-items: center;
        color: main.$color-primary-500;
        margin-top: main.$spacing-6;
    }

    .external-link {
        // remove link styles
        all: unset;

        &:hover {
            color: main.$color-primary-darker;
            text-decoration: underline;
            cursor: pointer;
        }
    }
</style>
