<script setup lang="ts">
    import Button from 'primevue/button';
    import Menu from 'primevue/menu';
    import { computed, ref } from 'vue';
    import { useNavigationItemsAction, useNavigationItemsQuery } from '@/composables/navigation-items';
    import { useI18n } from 'vue-i18n';
    import Dialog from 'primevue/dialog';
    import { createReusableTemplate } from '@vueuse/core';
    import { ProvisionType } from '@containex/portal-backend-dto';
    import { NavArea } from '@/types/NavArea';
    import router from '@/router';
    import { Navigation } from '@/router/navigation';
    import { useProductAction } from '@/composables/product';
    import { useProvisionTypeQuery } from '@/composables/provision-type';
    import { componentStyle } from '@/styling/util/component-style.ts';
    import { buttonFontWeight500 } from '@/styling/components/button';
    import Divider from 'primevue/divider';
    import { useMarketQuery } from '@/composables/market';

    withDefaults(
        defineProps<{
            isMobile?: boolean;
        }>(),
        {
            isMobile: false,
        }
    );

    const emits = defineEmits<{
        close: [];
    }>();

    const { t } = useI18n();
    const { activeArea, navAreas } = useNavigationItemsQuery();
    const navigationItemsAction = useNavigationItemsAction();
    const { currentProvisionType } = useProvisionTypeQuery();
    const productAction = useProductAction();
    const { market } = useMarketQuery();

    const [DefineTemplate, ReuseTemplate] = createReusableTemplate();

    const navAreasMenu = ref();
    const mobileNavAreaSelectVisible = ref(false);

    async function handleMenuItemClick(navArea: NavArea): Promise<void> {
        switch (navArea) {
            case NavArea.BUY:
                if (changeProvisionType(ProvisionType.Sales)) {
                    navigationItemsAction.setActiveArea(NavArea.BUY);
                    mobileNavAreaSelectVisible.value = false;
                    emits('close');
                    await router.push({ name: Navigation.SalesProductList });
                }
                break;
            case NavArea.RENT: {
                if (changeProvisionType(ProvisionType.Rental)) {
                    navigationItemsAction.setActiveArea(NavArea.RENT);
                    mobileNavAreaSelectVisible.value = false;
                    emits('close');
                    await router.push({ name: Navigation.RentalProductList });
                }
                break;
            }
            case NavArea.MY_AREA: {
                navigationItemsAction.setActiveArea(NavArea.MY_AREA);
                mobileNavAreaSelectVisible.value = false;
                emits('close');
                await router.push({ name: Navigation.Account });
            }
        }
    }

    function changeProvisionType(newProvisionType: ProvisionType): boolean {
        if (newProvisionType === currentProvisionType.value) {
            return true;
        }

        productAction.resetProductStoreForChangingProvisionType();
        return true;
    }

    const menuStyle = componentStyle({
        padding: '{spacing.2}',
    });

    const dividerStyle = componentStyle({
        horizontalMargin: '6px 0 6px 0',
    });

    const dialogStyle = componentStyle({
        headerPadding: '{spacing.2}',
        contentPadding: '{spacing.2}',
    });

    const externalLink = computed(() => {
        const marketCode = market.value?.code.toLowerCase() ?? 'at';
        return `https://www.containex.com/${marketCode}`;
    });
</script>

<template>
    <DefineTemplate>
        <Menu
            :id="isMobile ? 'nav-area-menu-mobile' : 'nav-area-menu'"
            ref="navAreasMenu"
            :model="Object.values(navAreas)"
            :class="isMobile ? 'mobile-nav-area-menu' : 'nav-area-menu'"
            :popup="!isMobile"
            :dt="menuStyle"
        >
            <template #item="{ item }">
                <div
                    class="menu-item-container color-text"
                    :class="{ active: activeArea === item.navArea }"
                    :data-testid="`nav-area-select-${item.plainName}`"
                    @click="handleMenuItemClick(item.navArea)"
                >
                    <div class="icon-container">
                        <span :class="item.icon" />
                    </div>
                    <div class="text-container">
                        <div class="text-base-bold-line-height-auto">{{ item.name }}</div>
                        <div class="text-base-regular-line-height-auto">
                            {{ item.description }}
                        </div>
                    </div>
                </div>
            </template>
            <template #end>
                <Divider :dt="dividerStyle" />
                <a :href="externalLink" target="_blank" class="external-link-no-hover">
                    <div class="menu-item-container-extra-padding">
                        <div class="menu-item-container color-text" :data-testid="`nav-area-select-containex`">
                            <div class="icon-container">
                                <span class="pi pi-external-link" />
                            </div>
                            <div class="text-container">
                                <div class="text-base-bold-line-height-auto">
                                    {{ t('NAVIGATION.AREAS.CONTAINEX_WEBSITE') }}
                                </div>
                                <div class="text-base-regular-line-height-auto">
                                    {{ t('NAVIGATION.AREAS.CONTAINEX_WEBSITE_DESCRIPTION') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </template>
        </Menu>
    </DefineTemplate>
    <Button
        :label="navAreas[activeArea]!.name"
        :dt="buttonFontWeight500"
        icon="pi pi-th-large"
        class="select-nav-area-btn text-base-semibold-line-height-auto"
        :aria-haspopup="true"
        :aria-controls="isMobile ? 'nav-area-menu-mobile' : 'nav-area-menu'"
        :text="isMobile"
        :class="{ mobile: isMobile }"
        data-testid="nav-area-select-button"
        @click="(event) => (isMobile ? (mobileNavAreaSelectVisible = true) : navAreasMenu.toggle(event))"
    />
    <ReuseTemplate v-if="!isMobile" />
    <Dialog
        v-else
        v-model:visible="mobileNavAreaSelectVisible"
        dismissable-mask
        modal
        style="width: 95%"
        :dt="dialogStyle"
    >
        <template #header>
            <div></div>
        </template>
        <ReuseTemplate />
    </Dialog>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .select-nav-area-btn {
        border-radius: 4px;
        text-align: left;
        box-shadow: none;

        &.mobile {
            color: main.$color-text;
            width: 100%;
            padding: main.$spacing-4;
            justify-content: left;
            border-radius: 0;
        }

        &:not(.mobile) {
            height: 32px;
            border: 1px solid rgba(255, 255, 255, 50%);
            background: transparent;
        }

        &:not(.mobile):hover {
            background-color: main.$color-primary-500;
        }
    }

    .title {
        color: main.$color-text;
        padding-bottom: main.$spacing-5;
    }

    .menu-item-container {
        display: flex;
        align-items: center;
        padding: main.$spacing-3;
        cursor: pointer;

        &:hover {
            background-color: main.$color-background-bluegray;
        }
    }

    .icon-container {
        padding: main.$spacing-3;
        line-height: 1;

        .pi {
            font-size: 24px;
        }
    }

    .text-container {
        display: flex;
        flex-direction: column;
        padding: main.$spacing-3;
    }

    .active {
        &.menu-item-container {
            background: main.$color-background-lightblue;
            border-radius: 4px;
        }

        .icon-container {
            color: main.$color-primary-500;
        }
    }

    .mobile-menu-container {
        padding-bottom: main.$spacing-5;
    }

    .menu-item-container-extra-padding {
        padding: var(--p-menu-list-padding);
    }

    .external-link-no-hover {
        all: unset;
        color: main.$color-primary-500;
        text-decoration: none;
        cursor: pointer;
    }
</style>
