import { useAuthenticationStore } from '@/stores/authentication';
import { computed, type ComputedRef } from 'vue';
import { type CustomerDto, CustomerRole } from '@containex/portal-backend-dto';
import { LoginResult } from '@/model/login-result';
import useGlobalToast from '@/composables/useGlobalToast';
import { type AxiosResponse, HttpStatusCode, isAxiosError } from 'axios';
// eslint-disable-next-line import-x/no-cycle
import { useCartAction } from './cart';
import { type Task, useAsyncTask } from 'vue-concurrency';
import { useMarketQuery } from './market';
import { useCompanyStore } from '@/stores/company';
import { authApi, customerApi, customerUpdatePasswordApi, signupApi } from '@containex/portal-backend-api-client';
import { getLogger } from '@/logger/logger';
import { medusaClient } from '@/common/api/medusa-client';
import { httpClient } from '@/common/api/http-client';
import { ConsentFlags } from '@containex/portal-business-logic';
import { useCompanyAction } from '@/composables/company.ts';

export interface AuthenticationQuery {
    username: ComputedRef<string>;
    currentCustomer: ComputedRef<CustomerDto | undefined>;
    isUserLoggedIn: ComputedRef<boolean>;
    isAdmin: ComputedRef<boolean>;
    canSwitchCompanies: ComputedRef<boolean>;
}

export interface AuthenticationAction {
    fetchCurrentCustomerIfMissing: Task<void, []>;

    login(email: string, password: string): Promise<LoginResult>;

    cancelLogin(): void;

    getOtherCompanies(): Promise<void>;

    changeCompany(companyId: string): Promise<LoginResult>;

    completeLogin(companyId: string): Promise<LoginResult>;

    logout(): Promise<void>;

    requestPasswordReset(email: string, language: string): Promise<void>;

    resetPassword(email: string, token: string, password: string): Promise<void>;

    activateCustomer(email: string, password: string, token: string, consentFlags: ConsentFlags): Promise<void>;

    activateNewSignupCustomer(email: string, password: string, token: string): Promise<void>;

    updatePassword(oldPassword: string, newPassword: string): Promise<AxiosResponse<void>>;

    deleteExpiredSession(): void;
}

export function useAuthenticationQuery(): AuthenticationQuery {
    const store = useAuthenticationStore();

    return {
        username: computed(() => store.username ?? ''),
        currentCustomer: computed(() => store.currentCustomer),
        isUserLoggedIn: computed(() => (store.userMail?.length ?? 0) > 0),
        isAdmin: computed(() => store.currentCustomer?.roles?.includes(CustomerRole.ADMIN) === true),
        canSwitchCompanies: computed(() => store.currentCustomer?.hasMultipleTenants ?? false),
    };
}

export function useAuthenticationAction(): AuthenticationAction {
    const companyAction = useCompanyAction();
    const store = useAuthenticationStore();
    const cartAction = useCartAction();
    const logger = getLogger('AuthenticationAction');

    function deleteExpiredSession(): void {
        store.removeCurrentCustomer();
    }

    return {
        async login(email: string, password: string): Promise<LoginResult> {
            try {
                const body = { email, password };
                const response = await authApi.login(httpClient, body);
                if (response.data.customer != null) {
                    store.setCurrentCustomer(response.data.customer);
                    await companyAction.findCustomerCompany();
                    return LoginResult.Success;
                }
                if (response.data.completeAuthToken != null && response.data.assignedCompanies) {
                    store.setAuthCompletionToken(response.data.completeAuthToken);
                    store.setAuthEmail(email);
                    store.setAssignedCompanies(response.data.assignedCompanies);
                    return LoginResult.CompletionRequired;
                }
            } catch (error) {
                logger.error(error, 'login failed');
                const { errorToastOptions, addToast } = useGlobalToast();

                addToast({
                    ...errorToastOptions,
                    summary: 'LOGIN.ERROR_SUMMARY',
                    detail: 'LOGIN.ERROR_DETAIL',
                });
            }

            return LoginResult.Failure;
        },
        cancelLogin() {
            store.removeCurrentCustomer();
        },
        async getOtherCompanies(): Promise<void> {
            const response = await authApi.getOtherCompanies(httpClient);
            if (response.data.assignedCompanies) {
                store.setAssignedCompanies(response.data.assignedCompanies);
            }
        },
        async changeCompany(companyId: string): Promise<LoginResult> {
            const data = { companyId };
            const response = await authApi.switchCompany(httpClient, data);
            if (response.data.customer != null) {
                store.setCurrentCustomer(response.data.customer);
                await companyAction.findCustomerCompany();
                cartAction.resetCartStore();
                await cartAction.ensureCartExists();
                return LoginResult.Success;
            }
            return LoginResult.Failure;
        },
        async completeLogin(companyId: string): Promise<LoginResult> {
            if (store.authEmail == null || store.completionToken == null) {
                return LoginResult.Failure;
            }
            const data = { email: store.authEmail, completeAuthToken: store.completionToken, companyId };
            const response = await authApi.completeLogin(httpClient, data);
            if (response.data.customer != null) {
                store.setCurrentCustomer(response.data.customer);
                store.setAuthCompletionToken(undefined);
                store.setAuthEmail(undefined);
                await companyAction.findCustomerCompany();
                return LoginResult.Success;
            }
            return LoginResult.Failure;
        },
        async logout() {
            await medusaClient.auth.deleteSession();

            store.removeCurrentCustomer();
            cartAction.resetAllCartStores();
            useCompanyStore().$reset();
        },
        fetchCurrentCustomerIfMissing: useAsyncTask(async (): Promise<void> => {
            if (store.currentCustomer != null) {
                return;
            }

            try {
                const response = await customerApi.me(httpClient);

                store.setCurrentCustomer(response.data);
            } catch (error: unknown) {
                if (isAxiosError(error) && error.response?.status === HttpStatusCode.Unauthorized) {
                    deleteExpiredSession();
                } else if (isAxiosError(error) && error.response?.status === HttpStatusCode.NotFound) {
                    deleteExpiredSession();
                } else {
                    logger.error(error, 'Fetch current customer failed');
                    throw error;
                }
            }
        }).drop(),
        async requestPasswordReset(email: string, language: string): Promise<void> {
            const market = useMarketQuery().market.value?.code ?? '';

            await customerApi.requestResetPassword(httpClient, {
                email,
                language,
                market,
            });
        },
        async resetPassword(email: string, token: string, password: string): Promise<void> {
            await customerApi.resetPassword(httpClient, { email, password, token });
        },
        async activateCustomer(
            email: string,
            password: string,
            token: string,
            consentFlags: ConsentFlags
        ): Promise<void> {
            if (!consentFlags.areAllAccepted()) {
                return;
            }

            try {
                await customerApi.activateCustomer(httpClient, {
                    email,
                    password,
                    token,
                    agbAccepted: consentFlags.general_terms_accepted,
                    dsgvoAccepted: consentFlags.dsgvo_accepted,
                    termsOfServiceAccepted: consentFlags.terms_of_service_accepted,
                    dataForwardingAccepted: consentFlags.data_forwarding_accepted,
                    retailerForwardingAccepted: consentFlags.retailer_forwarding_accepted,
                });
            } catch (error: unknown) {
                if (isAxiosError(error) && error.response?.status === HttpStatusCode.Forbidden) {
                    logger.error(
                        error,
                        'Failed to activate customer, maybe not all consents were accepted or token expired'
                    );
                    throw error;
                } else {
                    logger.error(error, 'Failed to activate customer');
                    throw error;
                }
            }
        },
        async activateNewSignupCustomer(email: string, password: string, token: string): Promise<void> {
            try {
                await signupApi.activateNewSignupCustomer(httpClient, {
                    email,
                    password,
                    token,
                });
            } catch (error: unknown) {
                if (isAxiosError(error) && error.response?.status === HttpStatusCode.Forbidden) {
                    logger.error(error, 'Failed to activate customer, maybe agbs were not set or token expired');
                    throw error;
                } else {
                    logger.error(error, 'Failed to activate customer');
                    throw error;
                }
            }
        },
        async updatePassword(oldPassword: string, newPassword: string): Promise<AxiosResponse<void>> {
            return await customerUpdatePasswordApi.updatePassword(httpClient, {
                oldPassword,
                newPassword,
            });
        },
        deleteExpiredSession,
    };
}
