import { toEuro } from '@containex/portal-business-logic';
import { useI18n } from 'vue-i18n';

interface PriceFormatter {
    formatPrice(this: void, price: number): string;
}

export function usePriceFormatter(): PriceFormatter {
    const i18n = useI18n();

    return {
        formatPrice(price: number): string {
            const locale = i18n.locale.value;

            const currencySymbol =
                Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR' })
                    .formatToParts(1)
                    .find((x) => x.type === 'currency')?.value ?? '';

            const formatter = Intl.NumberFormat(locale, {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });

            const priceToFormat = price < 0 ? 0 : price;
            return `${currencySymbol} ${formatter.format(toEuro(priceToFormat))}`;
        },
    };
}
