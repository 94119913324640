import { defineStore } from 'pinia';
import { CtxOrderMinimalDto } from '@containex/portal-backend-dto';

interface CtxOrdersState {
    orders: CtxOrderMinimalDto[];
    totalOrderAmount: number;
}

export const useCtxOrderStore = defineStore('ctxOrders', {
    state: (): CtxOrdersState => ({
        orders: [],
        totalOrderAmount: 0,
    }),
});
