import type { CtxProductVariant } from '@containex/portal-backend-api-client';
import { calculateRentalPrice } from '@containex/portal-business-logic';

export function isDiscountedVariant(
    variant: CtxProductVariant,
    isRental?: boolean,
    isRentalDurationSet?: boolean,
    rentalStart?: Date,
    rentalEnd?: Date
): boolean {
    if (isRental === true) {
        if (
            variant.calculated_price != null &&
            variant.original_price != null &&
            calculateRentalPrice(variant.calculated_price, isRentalDurationSet ?? false, rentalStart, rentalEnd) !==
                variant.original_price
        ) {
            return true;
        }

        return false;
    }

    if (
        variant.calculated_price != null &&
        variant.original_price != null &&
        variant.calculated_price !== variant.original_price
    ) {
        return true;
    }

    return false;
}
