import { Environment, getEnvironment } from '@/util/environment.ts';
import { BASIC_PAGE_TITLE } from '@/constants.ts';

export function assemblePageTitle(mainPart?: string): string {
    const titleParts: string[] = [];
    const e: string = getEnvironment();

    if (e !== Environment.PRODUCTION) {
        titleParts.push(e.toUpperCase());
    }

    if (mainPart !== undefined) {
        titleParts.push(mainPart);
    }

    titleParts.push(BASIC_PAGE_TITLE);

    return titleParts.join(' - ');
}
