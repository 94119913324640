import { filterProductVariantForProvisionType } from '@/util/filterProductVariantForProvisionType';
import { optimalVariantForMarket } from '@/product/common/util/optimal-variant-for-market';
import type { CtxProduct, CtxProductVariant } from '@containex/portal-backend-api-client';

interface SplitInput {
    product?: CtxProduct;
    marketCode?: string;
    isRental: boolean;
    variantId?: string;
}

interface SplitResult {
    primaryVariant?: CtxProductVariant;
    otherVariants: CtxProductVariant[];
}

export function primaryAndOtherVariantSplit({ product, marketCode, isRental, variantId }: SplitInput): SplitResult {
    const variants = product?.variants ?? [];
    const filteredVariants = variants.filter((variant) => {
        return filterProductVariantForProvisionType(variant, marketCode, isRental);
    });
    const sortedVariants = filteredVariants.sort((a, b) => {
        if ((b.calculated_price ?? 0) > 0) {
            return 1;
        } else if ((a.calculated_price ?? 0) > 0) {
            return -1;
        }

        return 0;
    });

    const primaryVariant =
        sortedVariants.find((variant) => variant.id === variantId) ??
        optimalVariantForMarket(sortedVariants, marketCode, true);

    const otherVariants = sortedVariants
        .filter((variant) => variant.id !== primaryVariant?.id)
        .filter(
            (variant) =>
                variant.depotQuantity.quantity_available > 0 ||
                (variant.depotQuantity.quantity_ordered > 0 && variant.depotQuantity.completion_date != null)
        );

    return {
        primaryVariant,
        otherVariants,
    };
}
