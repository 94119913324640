import { ProductFetchType, useProductStore } from '@/stores/product';
import { computed, type ComputedRef } from 'vue';
import { useMarketQuery } from '@/composables/market';
import { DEFAULT_ITEMS_PER_PAGE } from '@/constants';
import { type CtxProduct, productApi } from '@containex/portal-backend-api-client';
import { ProductSortField, ProvisionType, SortDirection } from '@containex/portal-backend-dto';
import { useCartQuery } from './cart';
import { useProvisionTypeAction, useProvisionTypeQuery } from './provision-type';
import { httpClient } from '@/common/api/http-client';
import { ProvisionTypeNotGivenError } from '@/model/provision-type-not-given';

export interface ProductQuery {
    products: ComputedRef<CtxProduct[]>;
    currentProduct: ComputedRef<CtxProduct | undefined>;
    page: ComputedRef<number>;
    totalCount: ComputedRef<number>;
    facets: ComputedRef<Record<string, Record<string, number>> | null>;
    currentFacets: ComputedRef<string[]>;
    lastProvision: ComputedRef<ProvisionType | null>;
    sort: ComputedRef<{ field: ProductSortField; direction: SortDirection } | undefined>;
}

export interface ProductAction {
    fetchProducts(productFetchType?: ProductFetchType): Promise<void>;
    fetchProduct(id: string): Promise<void>;
    searchProductByArticleId(id: string): Promise<CtxProduct | null>;
    setCurrentFacets(currentFacets: string[]): void;
    setSort(field: ProductSortField, direction: SortDirection): void;
    resetSort(): void;
    resetProductStoreForChangingProvisionType(): void;
}

export function useProductQuery(): ProductQuery {
    const store = useProductStore();

    return {
        // We need to type-assert here, because pricedProduct is an extended omission of an entity, which causes some funky behaviour, and overriding this would cause even more of a headache
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        products: computed(() => store.products as CtxProduct[]),
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        currentProduct: computed(() => store.currentProduct as CtxProduct | undefined),
        page: computed(() => store.page),
        totalCount: computed(() => store.totalCount),
        facets: computed(() => store.facets),
        currentFacets: computed(() => store.currentFacets.flatMap((currentEntry) => currentEntry)),
        lastProvision: computed(() => store.lastProvision),
        sort: computed(() => store.sort),
    };
}

export function useProductAction(): ProductAction {
    const store = useProductStore();
    const { currentSalesChannelId, market } = useMarketQuery();
    const provisionTypeAction = useProvisionTypeAction();
    const { currentProvisionType, isRentalProvisionType } = useProvisionTypeQuery();
    const { cart, currentRegionId } = useCartQuery();

    return {
        // eslint-disable-next-line complexity
        async fetchProducts(productFetchType = ProductFetchType.LOAD_ADDITIONAL_PRODUCTS): Promise<void> {
            if (market.value == null) {
                throw new Error('Market is not set');
            }
            if (currentProvisionType.value == null) {
                throw new ProvisionTypeNotGivenError();
            }
            if (currentSalesChannelId.value == null) {
                throw new Error('Sales channel is not set');
            }
            if (currentRegionId.value == null) {
                throw new Error('Region is not set');
            }

            const offset = productFetchType === ProductFetchType.RESET_PRODUCTS ? 0 : store.products.length;

            const cartId = isRentalProvisionType.value ? cart.value?.id : undefined;
            const { data } = await productApi.fetchProducts(httpClient, {
                query: '',
                offset,
                marketId: market.value.id,
                provisionType: currentProvisionType.value,
                limit: DEFAULT_ITEMS_PER_PAGE,
                regionId: currentRegionId.value,
                salesChannelId: currentSalesChannelId.value,
                cartId,
                facets: store.currentFacets,
                sort: store.sort,
            });

            // Product is a very complex type and is thus not depicted via the api on purpose
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
            const products = data.products as unknown[] as CtxProduct[];

            if (productFetchType === ProductFetchType.RESET_PRODUCTS) {
                store.products = products;
            } else {
                store.products = store.products.concat(products);
            }
            store.totalCount = data.count;
            provisionTypeAction.setIsChangingProvisionType(false);
            const returnFacets = data.facets ?? null;
            if (returnFacets == null) {
                store.facets = null;
            } else if (store.facets == null || currentProvisionType.value !== store.lastProvision) {
                //We need to check the lastProvision as well, so we update the facets when we change provisions
                store.lastProvision = currentProvisionType.value;

                for (const [key, value] of Object.entries(returnFacets)) {
                    const splitted = key.split('.');
                    // filter out facets that do not belong to the market
                    // e.g. availability.at vs. availability.de
                    if (
                        splitted.length === 2 &&
                        splitted[splitted.length - 1]?.length === 2 &&
                        splitted[splitted.length - 1] !== market.value.code.toLowerCase()
                    ) {
                        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                        delete returnFacets[key];
                    }

                    // filter out facets where exactly one option is given
                    if (Object.keys(value).length === 1) {
                        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                        delete returnFacets[key];
                    }

                    // filter out facet for NOT_AVAILABLE products
                    if (key.startsWith('available') && returnFacets[key] != null) {
                        // eslint-disable-next-line @typescript-eslint/dot-notation
                        delete returnFacets[key]['NOT_AVAILABLE'];
                    }
                }

                store.facets = returnFacets;
            }
        },
        async fetchProduct(id: string): Promise<void> {
            store.currentProduct = undefined;
            const salesChannelId = currentSalesChannelId.value;
            const regionId = currentRegionId.value;
            const cartId = isRentalProvisionType.value ? cart.value?.id : undefined;

            const { data } = await productApi.fetchProduct(httpClient, id, regionId, salesChannelId, cartId);

            store.currentProduct = data.product;
        },
        async searchProductByArticleId(articleId: string): Promise<CtxProduct | null> {
            const salesChannelId = currentSalesChannelId.value;
            const regionId = currentRegionId.value;
            const cartId = cart.value?.id;
            const provisionType = currentProvisionType.value;

            if (salesChannelId == null) {
                return null;
            }
            if (provisionType == null) {
                return null;
            }

            const { data } = await productApi.searchProductByArticleId(httpClient, {
                salesChannelId,
                articleId,
                regionId,
                cartId,
                provisionType,
            });

            return data.product ?? null;
        },
        setCurrentFacets(currentFacets: string[]): void {
            const facetsMap: Map<string, string[]> = new Map();
            for (const cf of currentFacets) {
                const prefix = cf.split(':')[0];
                if (prefix == null) {
                    continue;
                }

                const array = facetsMap.get(prefix) ?? [];

                array.push(cf);
                facetsMap.set(prefix, array);
            }
            store.currentFacets = Array.from(facetsMap.values());
        },
        setSort(field: ProductSortField, direction: SortDirection): void {
            store.sort = {
                field,
                direction,
            };
        },
        resetSort(): void {
            store.sort = undefined;
        },
        resetProductStoreForChangingProvisionType(): void {
            provisionTypeAction.setIsChangingProvisionType(true);
            store.products = [];
            store.facets = null;
            store.currentFacets = [];
            store.currentProduct = undefined;
            store.totalCount = 0;
            store.page = 0;
        },
    };
}
