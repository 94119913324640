import { defineStore } from 'pinia';
import { type CtxProduct } from '@containex/portal-backend-api-client';
import { ProductSortField, ProvisionType, SortDirection } from '@containex/portal-backend-dto';

export enum ProductFetchType {
    RESET_PRODUCTS,
    LOAD_ADDITIONAL_PRODUCTS,
}

interface ProductsState {
    products: CtxProduct[];
    currentProduct: CtxProduct | undefined;
    page: number;
    totalCount: number;
    facets: Record<string, Record<string, number>> | null;
    lastProvision: ProvisionType | null;
    currentFacets: string[][];
    sort:
        | {
              field: ProductSortField;
              direction: SortDirection;
          }
        | undefined;
}

export const useProductStore = defineStore('products', {
    state: (): ProductsState => ({
        products: [],
        currentProduct: undefined,
        page: 0,
        totalCount: 0,
        facets: null,
        lastProvision: null,
        currentFacets: [],
        sort: undefined,
    }),
});
