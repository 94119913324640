<script setup lang="ts">
    import { useCartQuery } from '@/composables/cart';
    import CartItem from '@/cart/components/CartItem.vue';
    import CartTotal from '@/cart/components/CartTotal.vue';
    import router from '@/router';
    import { Navigation } from '@/router/navigation';
    import Button from 'primevue/button';
    import { useI18n } from 'vue-i18n';
    import { computed } from 'vue';
    import { partition } from '@containex/common-utils';
    import { CONTAINER_ARTICLE_TYPES } from '@containex/portal-business-logic';
    import { lineItemsWithoutChilds, sortLineItemsByCreationDate } from '@/util/line-items';
    import { useCheckoutCartAction } from '@/checkout/composables/checkout-cart';
    import type { CartLineItemDiscountDto } from '@containex/portal-backend-dto';
    import type { LineItem } from '@containex/portal-backend-api-client';

    const { t } = useI18n();
    const { cart, lineItemsQuantityCount } = useCartQuery();
    const checkoutCartAction = useCheckoutCartAction();

    checkoutCartAction.resetCheckoutCart();
    if (cart.value != null) {
        checkoutCartAction.initCheckoutCart(cart.value);
    }

    const discounts = computed(() => {
        const discountMap: Record<string, CartLineItemDiscountDto> = {};
        cart.value?.discount?.discounts?.forEach((d) => {
            discountMap[d.lineItemId] = d;
        });

        return discountMap;
    });

    interface LineItemWithDiscount extends LineItem {
        discounts?: CartLineItemDiscountDto;
    }

    const lineItems = computed<{ productLineItems: LineItemWithDiscount[]; additionalProducts: LineItem[] }>(() => {
        const items = cart.value?.items ?? [];
        const lineItemsWithoutSpecialCosts = lineItemsWithoutChilds(items);
        const sortedLineItems = sortLineItemsByCreationDate(lineItemsWithoutSpecialCosts);

        const groupedLineItems = partition(sortedLineItems, (item) =>
            CONTAINER_ARTICLE_TYPES.includes(item.variant?.product?.article_type ?? '')
        );

        const products = groupedLineItems[0].map((productLineItem) => {
            return {
                ...productLineItem,
                discounts: discounts.value[productLineItem.line_item_group_id] ?? undefined,
            };
        });

        return {
            productLineItems: products,
            additionalProducts: groupedLineItems[1],
        };
    });

    function getDiscountForLineItem(lineItemId: string): CartLineItemDiscountDto | null {
        return cart.value?.discount?.discounts?.find((discount) => discount.lineItemId === lineItemId) ?? null;
    }
</script>

<template>
    <template v-if="cart != null && lineItemsQuantityCount > 0">
        <h2 class="cart-header text-4xl-bold-line-height-auto">{{ t('CART.CART') }}</h2>

        <div class="cart-container">
            <div class="cart-main-part">
                <div class="cart-item-list">
                    <CartItem
                        v-for="lineItem in lineItems.productLineItems"
                        :key="lineItem.id"
                        :line-item="lineItem"
                        :discount="getDiscountForLineItem(lineItem.id)"
                        :is-rental="false"
                        data-testid="cart-item"
                    />
                </div>
            </div>
            <div class="cart-sidebar">
                <div class="cart-total-wrap-container">
                    <CartTotal :cart="cart" :show-checkout-button="true" :is-rental="false" />
                </div>
            </div>
        </div>
    </template>

    <template v-else>
        <div class="empty-cart-container">
            <span class="pi pi-shopping-cart icon" />
            <h2 class="heading">{{ t('CART.CART_EMPTY') }}</h2>
            <p>{{ t('CART.CART_EMPTY_TEXT') }}</p>
            <Button
                icon="pi pi-percentage"
                :label="t('NOT_FOUND.CTA_BUTTON')"
                data-testid="cart-empty-button"
                @click="router.push({ name: Navigation.SalesProductList })"
            />
        </div>
    </template>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .cart-header {
        padding-bottom: main.$spacing-6;
    }

    .cart-container {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-5;
    }

    .cart-main-part {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: main.$spacing-5;
    }

    .cart-sidebar {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        gap: main.$spacing-6;
    }

    @include main.for-breakpoint-lg {
        .cart-container {
            flex-direction: row;
        }

        .cart-sidebar {
            min-width: 384px;
        }
    }

    .cart-item-list {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-5;
    }

    .add-additional-product-button {
        align-self: start;
    }

    .cart-delivery-container {
        background: main.$color-background-bluegray;
        padding: main.$spacing-4;
    }

    .cart-delivery-text {
        padding-top: main.$spacing-3;
        padding-bottom: main.$spacing-3;
    }

    .cart-total-wrap-container {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }

    .empty-cart-container {
        margin: 0 auto;
        background: main.$color-white;
        padding: main.$spacing-6;
        text-align: center;
        max-width: 588px;
    }

    .heading {
        font-weight: 700;
    }

    .icon {
        font-size: 36px;
        color: main.$color-secondary;
        padding-bottom: main.$spacing-6;
    }
</style>
