import { useSalesCartStore } from '@/stores/sales-cart';
import type { MedusaError } from '@medusajs/utils/dist/common/errors';
import {
    type AddressDto,
    type UpdateLineItemGroupRequestDto,
    TransportCostDto,
    DepotMinimalDto,
    OrderMinimalDto,
    ProvisionType,
} from '@containex/portal-backend-dto';
import { isMedusaErrorResponse } from '@/model/medusa-error-response';
import { useToastHelpers } from '@/composables/showToastHelpers';
import { CartNotInitializedError } from '@/model/cart-not-initialized-error';
import { cartApi, type Cart } from '@containex/portal-backend-api-client';
import { useCartAction } from '@/composables/cart';
import { useCompanyQuery } from '@/composables/company';
import { PaymentSessionNotInitializedError } from '@/model/payment-session-not-initialized-error';
import { computed, type ComputedRef } from 'vue';
import { useMarketQuery } from '@/composables/market';
import { addressDtoToAddressPayload } from '@containex/portal-medusa-mapper';
import { getLogger } from '@/logger/logger';
import { httpClient } from '@/common/api/http-client';
import { medusaClient } from '@/common/api/medusa-client';
import { useProvisionTypeQuery } from '@/composables/provision-type';
import { useRentalCartStore } from '@/stores/rental-cart';
import { ProvisionTypeNotGivenError } from '@/model/provision-type-not-given';
import { useCheckoutCartAction } from './checkout-cart';

export interface CheckoutQuery {
    transportCosts: ComputedRef<TransportCostDto[]>;
    rentalDepots: ComputedRef<DepotMinimalDto[]>;
}

export interface CheckoutAction {
    updateCartShippingAddress(address: AddressDto, regionId: string | null): Promise<Cart | MedusaError>;

    createPaymentSessionsIfMissing(): Promise<void>;

    initLineItemGroups(): Promise<void>;

    updateLineItemGroup(item: UpdateLineItemGroupRequestDto['group'], newPrice: number): Promise<void>;

    updateLineItemGroupDepot(groupId: string, depotId: string): Promise<void>;

    calculateTransportCost(cartId: string): Promise<void>;

    completeCart(): Promise<OrderMinimalDto['id'] | null>;

    fetchRentalDepots(): Promise<void>;
}

export function useCheckoutQuery(): CheckoutQuery {
    const salesCartStore = useSalesCartStore();
    const rentalCartStore = useRentalCartStore();
    const { currentProvisionType } = useProvisionTypeQuery();

    const cartStore = computed(() => {
        switch (currentProvisionType.value) {
            case ProvisionType.Rental:
                return rentalCartStore;
            case ProvisionType.Sales:
                return salesCartStore;
            case undefined:
            default:
                throw new ProvisionTypeNotGivenError();
        }
    });

    return {
        transportCosts: computed(() => cartStore.value.transportCosts),
        rentalDepots: computed(() => cartStore.value.rentalDepots),
    };
}

export function useCheckoutAction(): CheckoutAction {
    const salesCartStore = useSalesCartStore();
    const rentalCartStore = useRentalCartStore();
    const { currentProvisionType } = useProvisionTypeQuery();

    const cartStore = computed(() => {
        switch (currentProvisionType.value) {
            case ProvisionType.Rental:
                return rentalCartStore;
            case ProvisionType.Sales:
                return salesCartStore;
            case undefined:
            default:
                throw new ProvisionTypeNotGivenError();
        }
    });

    const toastHelpers = useToastHelpers();
    const cartAction = useCartAction();
    const { company } = useCompanyQuery();
    const { market } = useMarketQuery();
    const checkoutCartAction = useCheckoutCartAction();

    const logger = getLogger('CheckoutAction');

    return {
        async updateCartShippingAddress(address: AddressDto, regionId: string | null): Promise<Cart | MedusaError> {
            if (cartStore.value.cart == null) {
                throw new CartNotInitializedError();
            }

            try {
                const { cart } = await cartApi.updateCart(medusaClient, cartStore.value.cart.id, {
                    ...(regionId != null ? { region_id: regionId } : {}),
                    shipping_address: addressDtoToAddressPayload(address),
                });

                cartStore.value.setCart(cart);
                checkoutCartAction.updateCheckoutCartShippingAddress(cart.shipping_address);

                if (address.postalCode != null) {
                    const updatedCart = await cartAction.updateSessionPostalCode(address.postalCode);
                    if (updatedCart != null) {
                        checkoutCartAction.updateCheckoutCartSessionPostalCode(updatedCart.sessionPostalCode);
                        return updatedCart;
                    }
                }

                return cart;
            } catch (error: unknown) {
                if (isMedusaErrorResponse(error)) {
                    logger.warn(error, 'Updating cart shipping address failed');
                    toastHelpers.showToastForCartShippingAddressUpdate(error.response.data);

                    return error.response.data;
                }

                logger.error(error, 'Updating cart shipping address failed');

                throw error;
            }
        },
        async createPaymentSessionsIfMissing() {
            if (cartStore.value.cart == null) {
                throw new CartNotInitializedError();
            }

            const cart =
                cartStore.value.cart.payment_session == null || cartStore.value.cart.payment_sessions.length === 0
                    ? (await cartApi.createPaymentSessions(medusaClient, cartStore.value.cart.id)).cart
                    : cartStore.value.cart;

            if (cart.payment_session == null) {
                throw new PaymentSessionNotInitializedError();
            }

            if (cart.payment_session.payment_method != null) {
                cartStore.value.setCart(cart);
                return;
            }

            await cartAction.initCartStore();
        },
        async initLineItemGroups() {
            if (cartStore.value.cart == null) {
                throw new CartNotInitializedError();
            }

            const { data } = await cartApi.setLineItemGroups(httpClient, {
                cartId: cartStore.value.cart.id,
            });

            cartStore.value.setCart(data);
            checkoutCartAction.updateCheckoutCartLineItemGroups(data.lineItemGroups);
        },
        async calculateTransportCost(): Promise<void> {
            if (cartStore.value.cart == null || market.value == null) {
                throw new CartNotInitializedError();
            }
            const { data } = await cartApi.calculateTransportCosts(httpClient, {
                cartId: cartStore.value.cart.id,
                marketCode: market.value?.code,
            });

            cartStore.value.setTransportCosts(data.transportCosts ?? []);
        },
        async updateLineItemGroup(item: UpdateLineItemGroupRequestDto['group'], newPrice: number): Promise<void> {
            if (cartStore.value.cart == null) {
                throw new CartNotInitializedError();
            }

            const { data } = await cartApi.updateLineItemGroup(httpClient, {
                cartId: cartStore.value.cart.id,
                group: {
                    id: item.id,
                    deliveryTime: item.deliveryTime,
                    transportType: item.transportType,
                    estimatedDeliveryDateStart: item.estimatedDeliveryDateStart,
                    estimatedDeliveryDateEnd: item.estimatedDeliveryDateEnd,
                },
                price: newPrice,
            });

            const updatedLineItemGroup = data.lineItemGroups.find((i) => i.id === item.id);
            if (updatedLineItemGroup) {
                checkoutCartAction.updateCheckoutCartLineItemGroup(updatedLineItemGroup);
            }

            cartStore.value.setCart(data);
        },
        async updateLineItemGroupDepot(groupId: string, depotId: string): Promise<void> {
            if (cartStore.value.cart == null) {
                throw new CartNotInitializedError();
            }

            const { data } = await cartApi.updateLineItemGroupDepot(httpClient, {
                cartId: cartStore.value.cart.id,
                groupId,
                depotId,
            });

            const updatedLineItemGroup = data.lineItemGroups.find((i) => i.id === groupId);
            if (updatedLineItemGroup) {
                checkoutCartAction.updateCheckoutCartLineItemGroup(updatedLineItemGroup);
            }

            cartStore.value.setCart(data);
        },
        async completeCart(): Promise<OrderMinimalDto['id'] | null> {
            if (cartStore.value.cart == null) {
                throw new CartNotInitializedError();
            }

            try {
                await cartApi.updateCart(medusaClient, cartStore.value.cart.id, {
                    billing_address: addressDtoToAddressPayload(company.value?.billingAddress ?? {}),
                });

                const response = await cartApi.completeCart(medusaClient, cartStore.value.cart.id);

                if (response.type === 'order') {
                    cartAction.resetCartStore();
                    return response.data.id;
                }

                return null;
            } catch (error) {
                logger.error(error, 'Completing cart failed');
                toastHelpers.showToastForCartCompleteError();
                return null;
            }
        },

        async fetchRentalDepots(): Promise<void> {
            const countryCode = market.value?.code;

            if (countryCode == null) {
                throw new Error('Market country code missing');
            }

            const { data } = await cartApi.fetchRentalDepots(httpClient, countryCode);

            cartStore.value.setRentalDepots(data.rentalDepots);
        },
    };
}
