<script setup lang="ts">
    import { useDateFormatter } from '@/composables/date-format';
    import type { OrderLineItemCtxOrderInformation } from '@containex/portal-backend-dto';
    import OrderItemStateChip from './OrderItemStateChip.vue';
    import { useI18n } from 'vue-i18n';
    import { isMobile } from '@/util/breakpoints';
    import Divider from 'primevue/divider';
    import { componentStyle } from '@/styling/util/component-style';
    import { computed } from 'vue';
    import { NO_DATA_DASH } from '@/constants';

    const props = defineProps<{
        information: OrderLineItemCtxOrderInformation;
        isRental: boolean;
    }>();

    const { t } = useI18n();
    const { dateFormatter } = useDateFormatter();

    const dividerStyle = componentStyle({
        horizontalMargin: '6px 0 6px 0',
    });

    const titleDeliveryDateOrRentalPeriod = computed(() => {
        return props.isRental ? t('ACCOUNT.ORDER_DETAIL.RENTAL_PERIOD') : t('ACCOUNT.ORDER_DETAIL.DELIVERY_DATE');
    });

    function renderSerialNumber(serialNumber: string | undefined | null): string {
        return serialNumber ?? NO_DATA_DASH;
    }

    function renderDeliveryDateOrRentalPeriod(
        isRental: boolean,
        deliveryDate: Date | undefined | null,
        rentalStart: Date | undefined | null,
        rentalEnd: Date | undefined | null
    ): string {
        if (!isRental && deliveryDate != null) {
            return dateFormatter.formatDateTwoDigits(new Date(deliveryDate));
        } else if (isRental && rentalStart != null && rentalEnd != null) {
            return `${dateFormatter.formatDateTwoDigits(new Date(rentalStart))} - ${dateFormatter.formatDateTwoDigits(new Date(rentalEnd))}`;
        }

        return NO_DATA_DASH;
    }
</script>
<template>
    <table v-if="!isMobile" class="embedded-table">
        <tr class="embedded-table-head">
            <td>{{ t('ACCOUNT.ORDER_DETAIL.SERIAL_NUMBER') }}</td>
            <td>{{ t('ACCOUNT.ORDER_DETAIL.STATE') }}</td>
            <td>{{ titleDeliveryDateOrRentalPeriod }}</td>
        </tr>
        <tr v-for="position of information.positions" :key="position.serial_number ?? undefined">
            <td>{{ renderSerialNumber(position.serial_number) }}</td>
            <td>
                <OrderItemStateChip v-if="position.state != null" :state="position.state" />
                <span v-else>{{ NO_DATA_DASH }}</span>
            </td>
            <td class="color-success">
                {{
                    renderDeliveryDateOrRentalPeriod(
                        isRental,
                        position.delivery_date,
                        position.rental_start,
                        position.rental_end
                    )
                }}
            </td>
        </tr>
    </table>

    <div v-else>
        <div
            v-for="position of information.positions"
            :key="position.serial_number ?? position.state"
            class="embedded-mobile-table"
        >
            <div class="embedded-mobile-table-head text-base-semibold-line-height-auto">
                {{ t('ACCOUNT.ORDER_DETAIL.SERIAL_NUMBER') }}
            </div>
            <div>{{ renderSerialNumber(position.serial_number) }}</div>
            <Divider class="divider" :dt="dividerStyle"></Divider>

            <div>{{ t('ACCOUNT.ORDER_DETAIL.STATE') }}</div>
            <div>
                <OrderItemStateChip v-if="position.state != null" :state="position.state" />
                <span v-else>{{ NO_DATA_DASH }}</span>
            </div>
            <Divider class="divider" :dt="dividerStyle"></Divider>

            <div>{{ titleDeliveryDateOrRentalPeriod }}</div>
            <div class="color-success">
                {{
                    renderDeliveryDateOrRentalPeriod(
                        isRental,
                        position.delivery_date,
                        position.rental_start,
                        position.rental_end
                    )
                }}
            </div>
        </div>
    </div>
</template>
<style scoped lang="scss">
    @use 'src/styling/main';

    .embedded-table {
        width: 100%;
        padding: 0;
        margin: 0;
        border-collapse: collapse;
    }

    .embedded-table tr td {
        padding-top: main.$spacing-3;
        padding-bottom: main.$spacing-3;
        color: main.$color-text;
        border-bottom: 1px solid main.$color-border-gray;
    }

    .embedded-table-head {
        color: main.$color-secondary;
    }

    .embedded-table tr:last-of-type td {
        border: 0;
    }

    .embedded-table tr td.color-success {
        color: main.$color-success-green;
    }

    .embedded-mobile-table {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-top: main.$spacing-5;
        margin-bottom: main.$spacing-5;
        color: main.$color-secondary;
        align-items: center;
    }

    .embedded-mobile-table-head {
        color: main.$color-text;
    }

    .embedded-mobile-table:last-of-type {
        margin-bottom: 0;
    }

    .divider {
        grid-column: 1 / -1;
    }
</style>
