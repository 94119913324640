<script setup lang="ts">
    import LoadingSpinner from '@/components/LoadingSpinner.vue';
    import ProductListItem from '@/product/common/components/ProductListItem.vue';
    import ProductFilter from '@/product/common/components/ProductFilter.vue';
    import Button from 'primevue/button';
    import { useCartAction, useCartQuery } from '@/composables/cart';
    import { useProductAction, useProductQuery } from '@/composables/product';
    import { ProductFetchType } from '@/stores/product';
    import type { CtxProduct } from '@containex/portal-backend-api-client';
    import { useAsyncTask } from 'vue-concurrency';
    import { useMarketQuery } from '@/composables/market';
    import { computed, ref, watch } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { useOrderInfoSidebarAction } from '@/composables/order-info-sidebar';
    import { isMobile } from '@/util/breakpoints';
    import { useProvisionTypeQuery } from '@/composables/provision-type';
    import ProductSearch from '@/product/common/components/ProductSearch.vue';
    import ProductSort from '@/product/common/components/ProductSort.vue';
    import { useRouter } from 'vue-router';
    import { Navigation } from '@/router/navigation';

    const props = defineProps<{
        preSelectedFacets: string[];
        headlineTranslationKey: string;
    }>();

    const { products, totalCount, currentFacets, sort: sortByPrice } = useProductQuery();
    const productAction = useProductAction();
    const cartAction = useCartAction();
    const { market } = useMarketQuery();
    const { isChangingProvisionType } = useProvisionTypeQuery();
    const { currentRegionId, currentZipCode } = useCartQuery();
    const orderInfoSidebarAction = useOrderInfoSidebarAction();

    const { t } = useI18n();
    const router = useRouter();

    productAction.setCurrentFacets(props.preSelectedFacets);

    const fetchProductsTask = useAsyncTask(async (signal, fetchType: ProductFetchType) => {
        await productAction.fetchProducts(fetchType);
    }).drop();
    void fetchProductsTask.perform(ProductFetchType.RESET_PRODUCTS);

    async function handleAddToCart(productToAdd: CtxProduct, variantIdToAdd: string): Promise<void> {
        await cartAction.addLineItemByVariantId(productToAdd, variantIdToAdd);
    }

    // Reload products when switching region (different prices)
    watch(
        [currentRegionId],
        () => {
            if (currentRegionId.value != null) {
                void fetchProductsTask.perform(ProductFetchType.RESET_PRODUCTS);
            }
        },
        { immediate: true }
    );

    // Reload products and facets when switching between all/new/used products
    watch(
        [router.currentRoute.value.fullPath],
        () => {
            productAction.resetProductStoreForChangingProvisionType();
            productAction.setCurrentFacets(props.preSelectedFacets);
        },
        { immediate: true }
    );

    watch(
        [sortByPrice],
        () => {
            if (currentRegionId.value != null) {
                void fetchProductsTask.perform(ProductFetchType.RESET_PRODUCTS);
            }
        },
        { immediate: true }
    );

    const showFilterDialog = ref(false);

    async function updateFacets(facets: string[]): Promise<void> {
        showFilterDialog.value = false;
        productAction.setCurrentFacets(facets);
        await fetchProductsTask.perform(ProductFetchType.RESET_PRODUCTS);
    }

    // true, if no products retrieved and the only active facets is the pre-selected facets, as this implies e.g. there are simply no used/new products
    const showProductsNotFound = computed(
        () =>
            products.value.length === 0 &&
            currentFacets.value.filter((item) => !props.preSelectedFacets.includes(item)).length === 0
    );
</script>

<template>
    <div class="header-box">
        <h2 class="text-4xl-bold-line-height-auto">{{ t(headlineTranslationKey) }}</h2>
        <div v-if="isMobile === false" class="product-list-options">
            <ProductSearch />
            <ProductSort v-if="currentRegionId != null && currentZipCode != null" />
        </div>
    </div>
    <div v-if="showProductsNotFound && !fetchProductsTask.isRunning" class="no-products-found-container">
        <div class="no-products-found-content">
            <h3>{{ t('PRODUCT.NO_PRODUCTS_FOUND.TITLE') }}</h3>
            <div>
                {{ t('PRODUCT.NO_PRODUCTS_FOUND.DESCRIPTION') }}
            </div>
            <Button
                icon="pi pi-percentage"
                :label="t('PRODUCT.NO_PRODUCTS_FOUND.CTA_BUTTON')"
                @click="router.push(Navigation.SalesProductList)"
            />
        </div>
    </div>
    <template v-else>
        <div v-if="isMobile" class="mobile-buttons">
            <Button
                outlined
                class="mobile-filter-button text-base-semibold-line-height-auto"
                @click="showFilterDialog = true"
            >
                <i class="pi pi-filter"></i>
            </Button>
            <div class="mobile-product-search">
                <ProductSearch />
                <ProductSort v-if="currentRegionId != null && currentZipCode != null" />
            </div>
        </div>
        <div v-if="!isChangingProvisionType" class="product-list-wrapper">
            <ProductFilter
                :pre-selected-facets="preSelectedFacets"
                :active-facets="currentFacets"
                :show-filter-dialog="showFilterDialog"
                @update:active-facets="updateFacets"
                @close="showFilterDialog = false"
            />
            <div v-if="market != null" class="product-list-container">
                <div class="product-list">
                    <ProductListItem
                        v-for="(product, index) in products"
                        :key="product.id"
                        :data-testid="`product-list-item-${index}`"
                        :product="product"
                        :current-region-id="currentRegionId"
                        :postal-code="currentZipCode"
                        :is-rental="false"
                        :is-rental-duration-set="false"
                        :market="market"
                        :is-search-result="false"
                        @add-to-cart="handleAddToCart"
                        @show-price="orderInfoSidebarAction.setIsVisible(true)"
                    />
                </div>
                <div class="show-more">
                    <Button
                        v-if="products.length < totalCount && !fetchProductsTask.isRunning"
                        id="show-more-button"
                        outlined
                        class="text-base-bold-line-height-auto"
                        :disabled="fetchProductsTask.isRunning"
                        data-testid="product-list-show-more"
                        :label="t('PRODUCT.SHOW_MORE_PRODUCTS')"
                        @click="fetchProductsTask.perform(ProductFetchType.LOAD_ADDITIONAL_PRODUCTS)"
                    />
                    <LoadingSpinner v-else-if="fetchProductsTask.isRunning" />
                    <div v-if="products.length > 0 || !fetchProductsTask.isRunning" class="product-count">
                        {{ t('PRODUCT.X_OF_Y_PRODUCTS', { x: products.length, y: totalCount }) }}
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="center">
            <LoadingSpinner />
        </div>
    </template>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .no-products-found-container {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: main.$spacing-7;
    }

    .no-products-found-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 588px;
        text-align: center;
        gap: main.$spacing-5;
    }

    .header-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding-bottom: main.$spacing-5;
    }

    @include main.for-breakpoint-lg {
        .header-box {
            flex-direction: row;
        }
    }

    .product-list-options {
        display: flex;
        gap: main.$spacing-4;
        align-items: center;
    }

    .product-list-wrapper {
        display: grid;
        grid-template-columns: 1fr;
        gap: main.$spacing-6;
    }

    @include main.for-breakpoint-lg {
        .product-list-wrapper {
            grid-template-columns: 1fr 3fr;
        }
    }

    .product-list-container {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-5;
    }

    .center {
        display: flex;
        justify-content: center;
    }

    .product-list {
        display: grid;
        grid-template-columns: 100%;
        grid-gap: main.$spacing-5;
    }

    @include main.for-breakpoint-sm {
        .product-list {
            grid-template-columns: 1fr 1fr;
        }
    }

    @include main.for-breakpoint-md {
        .product-list {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    .show-more {
        text-align: center;
        padding-top: main.$spacing-6;
    }

    .product-count {
        padding-top: main.$spacing-2;
        color: main.$color-secondary;
    }

    .mobile-buttons {
        display: flex;
        gap: main.$spacing-5;
        padding-bottom: main.$spacing-6;
        justify-content: space-between;
    }

    .mobile-filter-button {
        gap: main.$spacing-3;
    }

    .mobile-product-search {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: main.$spacing-2;
    }

    #show-more-button {
        &:hover {
            background-color: main.$color-background-bluegray;
        }
    }
</style>
