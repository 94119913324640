<script setup lang="ts">
    import { InvoiceStatus } from '@containex/portal-backend-dto';
    import { computed } from 'vue';
    import Chip from 'primevue/chip';
    import { useI18n } from 'vue-i18n';
    import { getLogger } from '@/logger/logger';
    import { componentStyle } from '@/styling/util/component-style';

    const props = defineProps<{
        state: InvoiceStatus;
    }>();

    const { t } = useI18n();
    const logger = getLogger('InvoiceChip');

    const paidChipDesignTokens = {
        color: '{ color.statusGreenColor }',
        background: '{ color.statusGreenBackground }',
    };

    const openChipDesignTokens = {
        color: '{ color.statusRedColor }',
        background: '{ color.statusRedBackground }',
    };

    const partiallyPaidChipDesignTokens = {
        color: '{ color.statusOrangeColor }',
        background: '{ color.statusOrangeBackground }',
    };

    const invoiceData = computed(() => {
        switch (props.state) {
            case InvoiceStatus.Paid:
                return {
                    label: t('INVOICE.INVOICE_STATUS_TYPE.PAID'),
                    designTokens: paidChipDesignTokens,
                };
            case InvoiceStatus.Partially:
                return {
                    label: t('INVOICE.INVOICE_STATUS_TYPE.PARTIALLY'),
                    designTokens: partiallyPaidChipDesignTokens,
                };
            case InvoiceStatus.Open:
                return {
                    label: t('INVOICE.INVOICE_STATUS_TYPE.OPEN'),
                    designTokens: openChipDesignTokens,
                };
            default:
                //eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                logger.error(`no matching InvoiceStatus found for given status: ${props.state}`);
                return {};
        }
    });

    const chipStyle = computed(() =>
        componentStyle({
            paddingX: '0.5rem',
            borderRadius: '6px',
            ...invoiceData.value.designTokens,
        })
    );
</script>

<template>
    <Chip :dt="chipStyle" :label="invoiceData.label" />
</template>
