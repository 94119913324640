<script setup lang="ts">
    import LoadingSpinner from '@/components/LoadingSpinner.vue';
    import { useOrderAction, useOrderQuery } from '@/account/composables/order';
    import { useAsyncTask } from 'vue-concurrency';
    import { useI18n } from 'vue-i18n';
    import { computed, ref } from 'vue';
    import { DEFAULT_ROW_AMOUNT_OPTIONS_TABLE, DEFAULT_ROWS_PER_TABLE } from '@/constants';
    import {
        type FetchPaginatedDataDto,
        OrderMinimalDto,
        PaginationStep,
        ProvisionType,
    } from '@containex/portal-backend-dto';
    import { getLogger } from '@containex/logger';
    import Paginator, { type PageState } from 'primevue/paginator';
    import BlockUI from 'primevue/blockui';
    import { useDateFormatter } from '@/composables/date-format';
    import { marketContainsProvision } from '@/util/marketContainsProvision';
    import { useMarketQuery } from '@/composables/market';
    import { Navigation } from '@/router/navigation';
    import ReceiptListItem, {
        type ReceiptAdditionalInformation,
        type ReceiptHeaderItem,
    } from '../components/ReceiptListItem.vue';
    import { isDefined } from '@containex/common-utils';

    const { t } = useI18n();
    const { dateFormatter } = useDateFormatter();
    const { orders, totalOrderAmount } = useOrderQuery();
    const orderAction = useOrderAction();
    const logger = getLogger('OderListView');
    const { market } = useMarketQuery();

    const isLoading = ref(false);
    const paginatorCurrentRows = ref(DEFAULT_ROWS_PER_TABLE);
    const paginatorCurrentPage = ref(0);
    const paginatorPageLinkSize = ref(2);

    const task = useAsyncTask(async () => {
        await orderAction.loadOrders(paginatorCurrentRows.value, PaginationStep.New);
    }).perform();

    const fetchPaginatedOrders = useAsyncTask(async (signal, data: FetchPaginatedDataDto) => {
        logger.debug('OrderListView', 'Fetching orders for table');
        isLoading.value = true;
        await orderAction.loadOrders(data.dataAmount, data.step);
        isLoading.value = false;
    }).drop();

    const hasMarketRentalProvisionType = computed(() => {
        if (market.value != null) {
            return marketContainsProvision(market.value, ProvisionType.Rental);
        }
        return false;
    });

    async function handlePaginatorChange(event: PageState): Promise<void> {
        if (paginatorCurrentPage.value === event.page && paginatorCurrentRows.value === event.rows) {
            return;
        }

        if (paginatorCurrentRows.value !== event.rows) {
            paginatorCurrentRows.value = event.rows;
            await fetchPaginatedOrders.perform({
                dataAmount: event.rows,
                step: PaginationStep.New,
            });
            event.page = 0;
        } else {
            await fetchPaginatedOrders.perform({
                dataAmount: event.rows,
                step: paginatorCurrentPage.value < event.page ? PaginationStep.Next : PaginationStep.Previous,
            });
        }

        paginatorCurrentPage.value = event.page;
        getPageLinkSize();
    }

    function getPageLinkSize(): void {
        const lastPage = Math.ceil(totalOrderAmount.value / paginatorCurrentRows.value) - 1;
        if (paginatorCurrentPage.value === 0 || paginatorCurrentPage.value === lastPage) {
            paginatorPageLinkSize.value = 2;
        } else {
            paginatorPageLinkSize.value = 3;
        }
    }

    function getHeaderItems(order: OrderMinimalDto): ReceiptHeaderItem[] {
        // TODO: CPP-1081 - status is not yet a thing we have for an order
        return [
            {
                title: t('ORDER_LIST.ORDER'),
                value: order.id,
            },
            hasMarketRentalProvisionType.value
                ? {
                      title: t('ORDER_LIST.ORDER_TYPE'),
                      value: order.order_type_is_rental
                          ? t('ORDER_LIST.ORDER_TYPE_RENTAL')
                          : t('ORDER_LIST.ORDER_TYPE_BUY'),
                  }
                : undefined,
            {
                title: t('ORDER_LIST.ORDER_DATE'),
                value: dateFormatter.formatDateTwoDigits(new Date(order.created_at)),
            },
            { title: t('ORDER_LIST.ORDER_ORDERER'), value: order.company_name },
        ].filter((item) => isDefined(item));
    }

    function getAdditionalInformation(order: OrderMinimalDto): ReceiptAdditionalInformation {
        return {
            quantity: order.amount_articles,
            amount: order.subtotal,
            countryCode: order.delivery_country_code ?? undefined,
            postalCode: order.delivery_postal_code ?? undefined,
            isRental: order.order_type_is_rental,
            rentalStart: order.rental_start ?? undefined,
            rentalEnd: order.rental_end ?? undefined,
        };
    }
</script>

<template>
    <div class="order-list-page-container">
        <h2 class="text-2xl-bold-line-height-auto">{{ t('ORDER_LIST.TITLE') }}</h2>
        <div v-if="task.isSuccessful" class="order-list-container">
            <BlockUI :blocked="fetchPaginatedOrders.isRunning" class="full-width">
                <template v-if="orders.length > 0">
                    <div v-for="order of orders" :key="order.id" class="order-container">
                        <ReceiptListItem
                            :header-items="getHeaderItems(order)"
                            :details-link="{
                                navigation: Navigation.OrderDetail,
                                text: t('ORDER_LIST.ORDER_DETAILS'),
                                id: order.id,
                            }"
                            :additional-information="getAdditionalInformation(order)"
                            :items="order.items"
                        />
                    </div>
                </template>
                <div v-else>
                    <p>{{ t('ORDER_LIST.NO_ORDERS') }}</p>
                </div>
                <Paginator
                    v-if="orders.length > 0 && !fetchPaginatedOrders.isRunning"
                    :rows="paginatorCurrentRows"
                    :total-records="totalOrderAmount"
                    :rows-per-page-options="DEFAULT_ROW_AMOUNT_OPTIONS_TABLE"
                    :first="paginatorCurrentPage * paginatorCurrentRows"
                    :page-link-size="paginatorPageLinkSize"
                    :current-page-report-template="
                        t('ACCOUNT.PAGINATOR', {
                            first: '{first}',
                            last: '{last}',
                            totalRecords: '{totalRecords}',
                        })
                    "
                    template="CurrentPageReport PrevPageLink PageLinks NextPageLink RowsPerPageDropdown"
                    @page="handlePaginatorChange"
                ></Paginator>
            </BlockUI>
        </div>
        <LoadingSpinner v-else-if="task.isRunning" />
    </div>
</template>
<style scoped lang="scss">
    @use 'src/styling/main';

    .order-list-page-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        row-gap: main.$spacing-5;
    }

    .order-list-container {
        display: flex;
        flex-direction: row;
        gap: main.$spacing-5;
    }

    .order-container {
        border: 1px solid main.$color-background-lightgray;
        border-radius: 6px;
        margin-bottom: main.$spacing-6;
    }
</style>
