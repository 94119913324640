<script setup lang="ts">
    import { computed } from 'vue';
    import { useI18n } from 'vue-i18n';
    import { useCartQuery } from '@/composables/cart';
    import { usePriceFormatter } from '@/composables/formatPrice';
    import CheckoutCartItem from './CheckoutCartItem.vue';
    import { useCheckoutQuery } from '../composables/checkout';
    import type { LineItem } from '@containex/portal-backend-api-client';
    import { TransportType } from '@containex/portal-backend-dto';
    import DeliveryCost from '@/checkout/components/common/DeliveryCost.vue';
    import Divider from 'primevue/divider';
    import CartRentalInformation from '@/components/cart/CartRentalInformation.vue';
    import CartCranageInformation from '@/components/cart/CartCranageInformation.vue';
    import { lineItemsWithoutChildsSortedByDateAndContainersAndAdditionalProducts } from '@/util/line-items';

    defineProps<{
        currentCheckoutStep: number;
        isRental: boolean;
    }>();

    const { t } = useI18n();
    const { cart } = useCartQuery();
    const { formatPrice } = usePriceFormatter();
    const { transportCosts } = useCheckoutQuery();

    const deliveryAddress = computed(() => cart.value?.shipping_address ?? null);
    const cartTotal = computed(() => cart.value?.total ?? 0);
    const unknownTransportCosts = computed(() => cart.value?.unknownTransportCosts ?? false);

    const joinedData = computed(() => {
        const lineItems: LineItem[] = cart.value?.items ?? [];
        const lineItemGroups = cart.value?.lineItemGroups ?? [];
        const sortedGroups = lineItemGroups.slice(0).sort((a, b) => a.id.localeCompare(b.id));

        return sortedGroups.flatMap((group) => {
            const items = lineItems.filter((item) => item.line_item_group_id === group.id);
            const groupTransportCost = transportCosts.value.find(
                (transportCost) => transportCost.lineItemGroupId === group.id
            );

            const transportOption = groupTransportCost?.transportTypeCosts.find(
                (option) => option.transportType === group.transport_type
            );

            if (items.length === 0 || transportOption == null) {
                return [];
            }

            return {
                items: lineItemsWithoutChildsSortedByDateAndContainersAndAdditionalProducts(items),
                group,
                transportOption,
            };
        });
    });

    const rentalTransportCost = computed(() => {
        const lineItemGroup = cart.value?.lineItemGroups[0];
        if (lineItemGroup == null) {
            return 0;
        }
        const groupTransportCost = transportCosts.value.find(
            (transportCost) => transportCost.lineItemGroupId === lineItemGroup.id
        );

        const transportOption = groupTransportCost?.transportTypeCosts.find(
            (option) => option.transportType === lineItemGroup.transport_type
        );
        return transportOption?.price ?? 0;
    });

    function getTranspotOptionTypeTranslation(transportType?: TransportType): string {
        //eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
        switch (transportType) {
            case TransportType.CraneTruck:
                return t('CART.STEPS.DELIVERY.METHOD.CRANE_TRUCK.TITLE');
            case TransportType.Truck:
                return t('CART.STEPS.DELIVERY.METHOD.TRUCK.TITLE');
            case TransportType.PickUp:
            default:
                return t('CART.STEPS.DELIVERY.METHOD.PICK_UP.TITLE');
        }
    }
</script>

<template>
    <div class="checkout-cart">
        <h3 class="text-xl-bold-line-height-auto">{{ t('CHECKOUT.CART.HEADER') }}</h3>
        <div class="cart-rental-information-font-size">
            <CartRentalInformation v-if="isRental" />
        </div>
        <div v-for="item in joinedData" :key="item.group.id" class="cart-item-list">
            <CheckoutCartItem
                v-for="(lineItem, index) in item.items"
                :key="lineItem.id"
                :data-testid="index === 0 ? 'checkout-cart-item-first' : undefined"
                :line-item-group="item.group"
                :line-item="lineItem"
                :delivery-address="deliveryAddress"
                :transport-option="item.transportOption"
                :is-rental="isRental"
            />
            <div v-if="!isRental" class="delivery-price-container">
                <div>
                    {{ getTranspotOptionTypeTranslation(item.group.transport_type) }}
                </div>
                <DeliveryCost :price="item.transportOption.price" />
            </div>
            <Divider class="divider" />
        </div>

        <CartCranageInformation v-if="isRental && cart != null" :cart="cart" :small="true" />

        <div v-if="isRental && rentalTransportCost > 0" class="cart-item-list">
            <div class="delivery-price-container">
                <div>
                    {{ t('CART.STEPS.DELIVERY.METHOD.RENTAL.DELIVERY') }}
                </div>
                <DeliveryCost :price="(rentalTransportCost ?? 0) / 2" />
            </div>
            <div class="delivery-price-container">
                <div>
                    {{ t('CART.STEPS.DELIVERY.METHOD.RENTAL.BACKHAUL') }}
                </div>
                <DeliveryCost :price="(rentalTransportCost ?? 0) / 2" />
            </div>
            <Divider class="divider" />
        </div>

        <div class="cart-footer">
            <div class="text-base-semibold-line-height-auto">{{ t('CHECKOUT.CART.TOTAL') }}</div>
            <div class="text-base-semibold-line-height-auto">
                <template v-if="unknownTransportCosts">{{ t('PRODUCT.PRICE_FROM') }}</template>
                {{ formatPrice(cartTotal) }}
            </div>
        </div>
        <div class="subtext">{{ t('CHECKOUT.CART.EXCLUDING_VAT') }}</div>
        <div v-if="unknownTransportCosts" class="subtext">{{ t('CHECKOUT.CART.EXCLUDING_TRANSPORT') }}</div>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .checkout-cart {
        height: fit-content;
        padding: main.$spacing-6 main.$spacing-5;
        background: main.$color-white;
        border-radius: 4px;
    }

    .divider {
        margin: 0;
    }

    .delivery-price-container {
        display: flex;
        justify-content: space-between;
        line-height: 1;
        font-size: main.$font-size-2;
    }

    .cart-item-list {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-5;
        padding-top: main.$spacing-5;
    }

    .cart-footer {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding-top: main.$spacing-5;
    }

    .subtext {
        font-size: main.$font-size-1;
        padding-top: main.$spacing-2;
    }

    .cart-rental-information-font-size {
        font-size: main.$font-size-2;
    }
</style>
