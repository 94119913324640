<script setup lang="ts">
    import { usePriceFormatter } from '@/composables/formatPrice';
    import { useCartAction, useCartQuery } from '@/composables/cart';
    import { computed, ref, watch } from 'vue';
    import { useDebounceFn } from '@vueuse/core';
    import Button from 'primevue/button';
    import Card from 'primevue/card';
    import { isMobile } from '@/util/breakpoints';
    import type { LineItem } from '@containex/portal-backend-api-client';
    import { isProductUsed } from '@/util/isProductUsed';
    import ChippedContent from '@/components/ChippedContent.vue';
    import ProductImage from '@/components/ProductImage.vue';
    import { useI18n } from 'vue-i18n';
    import { Navigation } from '@/router/navigation';
    import ProductAvailability from '@/components/ProductAvailability.vue';
    import DepotLocationWithIcon from '@/components/DepotLocationWithIcon.vue';
    import PriceInformation from '@/components/PriceInformation.vue';
    import { getAvailabilityForVariantBasedOnCartItem } from '@/util/variantAvailability';
    import {
        CONTAINER_ARTICLE_TYPES,
        DEPOT_TYPE_PRODUCTION_SITE_QUANTITY_LIMIT,
        getRentalDuration,
    } from '@containex/portal-business-logic';
    import { useCheckoutCartAction } from '@/checkout/composables/checkout-cart';
    import { type CartLineItemDiscountDto, DepotType } from '@containex/portal-backend-dto';

    const props = defineProps<{
        lineItem: LineItem;
        isRental: boolean;
        discount: CartLineItemDiscountDto | null;
    }>();

    const debounceTimeMs = 500;

    const cartAction = useCartAction();
    const { formatPrice } = usePriceFormatter();
    const { rentalEnd, rentalStart } = useCartQuery();
    const checkoutCartAction = useCheckoutCartAction();
    const { t } = useI18n();

    const displayPrice = computed(() => {
        if (props.isRental) {
            const rentalDuration = getRentalDuration(rentalStart.value, rentalEnd.value);
            if (rentalDuration > 0 && props.lineItem != null && props.lineItem.unit_price > 0) {
                return formatPrice(props.lineItem.unit_price / rentalDuration);
            }
            return '-';
        }
        return formatPrice(props.lineItem.unit_price ?? 0);
    });
    const isRentalDurationSet = computed(() => rentalStart.value != null && rentalEnd.value != null);

    const lastAcceptedQuantity = computed(() => props.lineItem.quantity);
    const currentQuantity = ref(1);
    const inventoryQuantity = computed(() => props.lineItem.variant.inventory_quantity ?? 0);
    const productAvailable = computed(() => {
        return getAvailabilityForVariantBasedOnCartItem(props.lineItem.variant, props.lineItem, false);
    });
    const productCompletionDate = computed(() => props.lineItem.variant.depotQuantity.completion_date);
    const productOnDemandCompletionDate = computed(
        () => props.lineItem.variant.depotQuantity.on_demand_completion_date
    );
    const product = computed(() => props.lineItem.variant.product);
    const depotLocation = computed(() => props.lineItem.variant.depotQuantity.depot.address.city);
    const depotType = computed(() => props.lineItem.variant.depotQuantity.depot.type);
    const itemClickable = computed(() => CONTAINER_ARTICLE_TYPES.includes(product.value?.article_type ?? ''));
    const maxQuantity = computed(() =>
        depotType.value === DepotType.ProductionSite
            ? DEPOT_TYPE_PRODUCTION_SITE_QUANTITY_LIMIT
            : inventoryQuantity.value
    );

    const debounceQuantityChange = useDebounceFn(async (newQuantity: number) => {
        if (newQuantity === lastAcceptedQuantity.value) {
            return;
        }
        const changeResponse = await cartAction.updateLineItemQuantity(props.lineItem, newQuantity);
        if ('type' in changeResponse && 'message' in changeResponse) {
            currentQuantity.value = lastAcceptedQuantity.value;
        } else {
            checkoutCartAction.updateCheckoutCartLineItems(changeResponse.items);
        }
    }, debounceTimeMs);

    function adjustQuantity(newQuantity: number): void {
        currentQuantity.value = Math.max(Math.min(newQuantity, maxQuantity.value), 0);
    }

    watch(
        lastAcceptedQuantity,
        () => {
            currentQuantity.value = lastAcceptedQuantity.value;
        },
        { immediate: true }
    );

    watch(currentQuantity, async (newQuantity: number) => {
        await debounceQuantityChange(newQuantity);
    });
</script>

<template>
    <div v-if="!isMobile" class="cart-item">
        <Card>
            <template #content>
                <div class="cart-item-container">
                    <div class="cart-item-image-container">
                        <ChippedContent
                            v-if="product != null"
                            :is-used="isProductUsed(product)"
                            :show-chip="!isRental"
                            :is-discounted="discount != null && discount.isDiscounted"
                        >
                            <template #content>
                                <router-link
                                    v-if="product != null && itemClickable"
                                    :to="{
                                        name: isRental ? Navigation.RentalProductDetail : Navigation.SalesProductDetail,
                                        params: { id: product.id, variantId: lineItem.variant.id },
                                    }"
                                    class="text-base-bold-line-height-auto cart-item-title"
                                >
                                    <ProductImage
                                        :url="lineItem.thumbnail ?? undefined"
                                        :alt="product.title ?? ''"
                                        class="product-image"
                                    />
                                </router-link>
                                <ProductImage
                                    v-else
                                    :url="lineItem.thumbnail ?? undefined"
                                    :alt="product.title ?? ''"
                                    class="product-image"
                                />
                            </template>
                        </ChippedContent>
                    </div>
                    <div class="cart-item-content">
                        <div>
                            <router-link
                                v-if="product != null && itemClickable"
                                :to="{
                                    name: isRental ? Navigation.RentalProductDetail : Navigation.SalesProductDetail,
                                    params: { id: product.id, variantId: lineItem.variant.id },
                                }"
                                class="text-base-bold-line-height-auto cart-item-title"
                            >
                                {{ product.title }}
                            </router-link>
                            <span v-else-if="product != null" class="text-base-bold-line-height-auto cart-item-title">{{
                                product.title
                            }}</span>
                            <div v-if="!isRental" class="cart-item-content-details">
                                <DepotLocationWithIcon :depot-type="depotType" :depot-location="depotLocation" />
                                <ProductAvailability
                                    :product-available="productAvailable"
                                    :completion-date="productCompletionDate"
                                    :on-demand-completion-date="productOnDemandCompletionDate"
                                />
                            </div>
                        </div>
                        <div class="cart-item-quantity-price-container">
                            <div class="cart-item-quantity-container">
                                <div class="cart-item-quantity">
                                    <Button
                                        outlined
                                        :icon="currentQuantity <= 1 ? 'pi pi-trash' : 'pi pi-minus'"
                                        data-testid="cart-item-quantity-less"
                                        @click="adjustQuantity(currentQuantity - 1)"
                                    />
                                    <div
                                        class="text-xl-bold-line-height-auto cart-item-quantity-label"
                                        data-testid="cart-item-quantity"
                                    >
                                        {{ currentQuantity }}
                                    </div>
                                    <Button
                                        outlined
                                        icon="pi pi-plus"
                                        :disabled="currentQuantity >= maxQuantity"
                                        data-testid="cart-item-quantity-more"
                                        @click="adjustQuantity(currentQuantity + 1)"
                                    />
                                </div>
                            </div>
                            <div>
                                <div class="text-base-bold-line-height-auto cart-item-unit-price">
                                    <span v-if="isRental">
                                        <span v-if="!isRentalDurationSet">
                                            {{ t('PRODUCT.PRICE_FROM') }}
                                        </span>
                                        {{ t('PRODUCT.PRICE_DAILY', { price: displayPrice }) }}
                                    </span>
                                    <span v-else>
                                        <template v-if="discount?.isDiscounted">
                                            <span class="cart-item-price-original">{{
                                                formatPrice(discount.originalPrice ?? 0)
                                            }}</span
                                            ><br />
                                            <span class="color-discount">{{ displayPrice }}</span>
                                        </template>
                                        <template v-else>
                                            {{ displayPrice }}
                                        </template>
                                    </span>
                                </div>
                                <div class="cart-item-price-information">
                                    <PriceInformation
                                        v-if="!isRental"
                                        :depot-type="depotType"
                                        :depot-location="depotLocation"
                                    />
                                    {{ t('CART.TOTAL.EXCLUDING_VAT') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </Card>
    </div>
    <div v-else class="cart-item-mobile">
        <Card>
            <template #content>
                <div class="cart-item-container-mobile">
                    <div class="cart-item-image-container-mobile">
                        <ChippedContent
                            v-if="product != null"
                            :is-used="isProductUsed(product)"
                            :show-chip="!isRental"
                            :is-discounted="discount != null && discount.isDiscounted"
                        >
                            <template #content>
                                <ProductImage
                                    :url="lineItem.thumbnail ?? undefined"
                                    :alt="product.title ?? ''"
                                    class="product-image product-image-mobile"
                                />
                            </template>
                        </ChippedContent>
                        <div class="cart-item-content cart-item-content-mobile">
                            <router-link
                                v-if="product != null"
                                :to="{
                                    name: isRental ? Navigation.RentalProductDetail : Navigation.SalesProductDetail,
                                    params: { id: product.id, variantId: lineItem.variant.id },
                                }"
                                class="text-base-bold-line-height-auto cart-item-title"
                            >
                                {{ product.title }}
                            </router-link>
                            <div v-if="!isRental" class="cart-item-content-details">
                                <DepotLocationWithIcon :depot-type="depotType" :depot-location="depotLocation" />
                                <ProductAvailability
                                    :product-available="productAvailable"
                                    :completion-date="productCompletionDate"
                                    :on-demand-completion-date="productOnDemandCompletionDate"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="cart-item-quantity-price-container-mobile">
                        <div class="cart-item-quantity-container">
                            <div class="cart-item-quantity">
                                <Button
                                    outlined
                                    :icon="currentQuantity === 1 ? 'pi pi-trash' : 'pi pi-minus'"
                                    @click="adjustQuantity(currentQuantity - 1)"
                                />
                                <div class="text-xl-bold-line-height-auto cart-item-quantity-label">
                                    {{ currentQuantity }}
                                </div>
                                <Button
                                    outlined
                                    icon="pi pi-plus"
                                    :disabled="currentQuantity >= maxQuantity"
                                    @click="adjustQuantity(currentQuantity + 1)"
                                />
                            </div>
                        </div>
                        <div>
                            <div class="text-base-bold-line-height-auto cart-item-unit-price">
                                <span v-if="isRental">
                                    <span v-if="!isRentalDurationSet">
                                        {{ t('PRODUCT.PRICE_FROM') }}
                                    </span>
                                    {{ t('PRODUCT.PRICE_DAILY', { price: displayPrice }) }}
                                </span>
                                <span v-else>
                                    <template v-if="discount?.isDiscounted">
                                        <span class="cart-item-price-original">{{
                                            formatPrice(discount.originalPrice ?? 0)
                                        }}</span
                                        ><br />
                                        <span class="color-discount">{{ displayPrice }}</span>
                                    </template>
                                    <template v-else>
                                        {{ displayPrice }}
                                    </template>
                                </span>
                            </div>
                            <div class="cart-item-price-information">
                                <PriceInformation
                                    v-if="!isRental"
                                    :depot-type="depotType"
                                    :depot-location="depotLocation"
                                />
                                {{ t('CART.TOTAL.EXCLUDING_VAT') }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </Card>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .cart-item :deep(.p-card .p-card-content) {
        padding: 0;
    }

    .cart-item-mobile :deep(.p-card) {
        border-radius: main.$spacing-2;
        box-shadow: none;
    }

    .cart-item-mobile :deep(.p-card .p-card-content) {
        padding: 0;
    }

    .cart-item-container {
        display: flex;
        justify-content: space-between;
        gap: main.$spacing-5;
        flex-wrap: wrap;
    }

    @include main.for-breakpoint-md {
        .cart-item-container {
            flex-wrap: nowrap;
        }
    }

    .cart-item-container-mobile {
        display: flex;
        justify-content: space-between;
        gap: main.$spacing-3;
        flex-direction: column;
    }

    .cart-item-image-container-mobile {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: main.$spacing-3;

        img {
            width: 100%;
            max-width: 200px;
            height: auto;
            object-fit: cover;
        }
    }

    .product-image {
        width: 100%;
        height: 200px;
    }

    .product-image-mobile {
        height: 150px;
    }

    .cart-item-image-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 100%;
        }
    }

    .chippedContent {
        width: 100%;
    }

    .cart-item-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: main.$spacing-3;
    }

    .cart-item-content-mobile {
        align-self: flex-start;
    }

    .cart-item-content-details {
        margin-top: main.$spacing-4;
    }

    .cart-item-title {
        text-decoration: none;
        color: main.$color-text;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        line-height: 1.1;
    }

    .cart-item-delivery {
        padding-top: main.$spacing-1;
    }

    .cart-item-delivery-icon-available-now {
        color: main.$color-green-dark;
    }

    .cart-item-delivery-icon-available-in-x {
        color: main.$color-yellow-500;
    }

    .cart-item-quantity-price-container {
        display: flex;
        justify-content: space-between;
    }

    .cart-item-quantity-price-container-mobile {
        display: flex;
        justify-content: space-between;
        gap: main.$spacing-3;
        width: 100%;
    }

    .cart-item-quantity-container {
        justify-content: flex-start;
        display: flex;
        align-items: flex-end;
        gap: main.$spacing-3;
    }

    .cart-item-quantity {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .cart-item-quantity-label {
        width: 4ch;
        text-align: center;
    }

    .cart-item-action-divider {
        border-right: 1px solid;
        border-color: main.$color-text-gray;
        height: main.$font-size-4;
    }

    .cart-item-delete-button {
        padding: 0;
    }

    .cart-item-quantity-field {
        text-align: center;
    }

    .cart-item-price-information {
        font-size: main.$font-size-3;
        text-align: right;
        padding-left: main.$spacing-4;
        color: main.$color-secondary;
    }

    .cart-item-unit-price {
        text-align: right;
    }

    .cart-item-price-original {
        font-weight: 400;
        font-size: main.$font-size-1;
        text-decoration: line-through;
    }
</style>
