<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import { computed, ref } from 'vue';
    import RadioCard from '@/components/form-components/RadioCard.vue';
    import Button from 'primevue/button';
    import { useAuthenticationStore } from '@/stores/authentication.ts';

    const emits = defineEmits<{
        companySelected: [string];
        dialogCanceled: [];
    }>();

    const authenticationStore = useAuthenticationStore();
    const { t } = useI18n();

    const selectedCompanyId = ref('');
    const assignedCompanies = computed(() => authenticationStore.assignedCompanies);

    function companySelected(): void {
        emits('companySelected', selectedCompanyId.value);
    }

    function selectCompanyId(companyId: string): void {
        selectedCompanyId.value = companyId;
    }
</script>

<template>
    <div class="selection-container">
        <template v-for="company in assignedCompanies" :key="company.id">
            <RadioCard
                :name="company.id"
                class="company-selection"
                :model-value="selectedCompanyId"
                :value="company.id"
                @update:model-value="selectCompanyId($event)"
            >
                <template #header>
                    <div v-if="company.externalId">{{ company?.externalId }} - {{ company.companyName }}</div>
                    <div v-else>{{ company.companyName }}</div>
                    <div>
                        {{ company.companyAddress.countryCode.toUpperCase() }} - {{ company.companyAddress.postalCode }}
                        {{ company.companyAddress.city }}
                    </div>
                </template>
            </RadioCard>
        </template>

        <br />
        <div class="actions">
            <Button :label="t('COMMON.CANCEL')" outlined type="button" autofocus @click="emits('dialogCanceled')" />
            <Button
                :disabled="selectedCompanyId === ''"
                type="button"
                :label="t('LOGIN.COMPLETE_LOGIN.COMPLETE')"
                @click="companySelected()"
            ></Button>
        </div>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .selection-container {
        margin-left: main.$spacing-6;
        margin-right: main.$spacing-6;
    }

    .company-selection {
        &:first-child {
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
        }

        &:last-child {
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
        }
    }

    .actions {
        display: flex;
        justify-content: end;
        gap: 1ch;
        padding-bottom: main.$spacing-6;
    }
</style>
