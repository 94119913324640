<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import { DepotType } from '@containex/portal-backend-dto';
    import { computed } from 'vue';

    const props = withDefaults(
        defineProps<{
            depotType: DepotType;
            depotLocation: string | null | undefined;
            additionalLocations?: number;
        }>(),
        {
            additionalLocations: 0,
        }
    );

    const { t } = useI18n();

    const additionalLocationString = computed(() => {
        if (props.additionalLocations <= 0) {
            return '';
        } else if (props.additionalLocations === 1) {
            return ` +${String(props.additionalLocations)} ${t('PRODUCT.ONE_DEPOT')}`;
        }
        return ` +${String(props.additionalLocations)} ${t('PRODUCT.MULTIPLE_DEPOTS')}`;
    });

    const iconClass = computed(() =>
        props.depotType === DepotType.ProductionSite || props.depotLocation == null
            ? 'pi pi-map-marker'
            : 'pi pi-building'
    );

    const text = computed(() =>
        props.depotType === DepotType.ProductionSite || props.depotLocation == null
            ? t('PRODUCT.SHIPPING_DETAILS.SHIPPING_INCLUDED')
            : props.depotLocation
    );
</script>

<template>
    <div class="icon-with-text-container">
        <i :class="iconClass" />
        <span>
            {{ text }}
            <span class="text-md-bold-line-height-auto">{{ additionalLocationString }}</span>
        </span>
    </div>
</template>
