<script setup lang="ts">
    import { computed, ref } from 'vue';
    import Button from 'primevue/button';
    import TieredMenu from 'primevue/tieredmenu';
    import type { MenuItem } from 'primevue/menuitem';
    import { componentStyle } from '@/styling/util/component-style';
    import { buttonFontWeight500 } from '@/styling/components/button';

    const props = defineProps<{
        menuItem: MenuItem;
    }>();

    const HIDE_MENU_DELAY_IN_MS = 250;
    const timeoutRef = ref();
    const tieredMenuRef = ref<InstanceType<typeof TieredMenu>>();

    const menuItems = computed(() => props.menuItem.items);

    function handleButtonMouseOver(event: Event): void {
        clearTimeout(timeoutRef.value);
        tieredMenuRef.value?.show(event);
    }

    function handleMouseLeave(): void {
        timeoutRef.value = setTimeout(() => {
            tieredMenuRef.value?.hide();
        }, HIDE_MENU_DELAY_IN_MS); // Small delay to allow for cursor transition
    }

    function handleTieredMenuMouseOver(): void {
        clearTimeout(timeoutRef.value);
    }

    const tieredMenuStyle = componentStyle({
        itemPadding: '1rem',
        itemBorderRadius: '0',
    });

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const menuItemLabel = props.menuItem.label as string;
</script>

<template>
    <Button
        icon="pi pi-angle-down"
        icon-pos="right"
        aria-controls="hover-menu-button-tiered-menu"
        class="hover-menu-button"
        :aria-haspopup="true"
        :label="menuItemLabel"
        :dt="buttonFontWeight500"
        @mouseover="handleButtonMouseOver"
        @mouseleave="handleMouseLeave"
        @click="handleButtonMouseOver"
    />
    <TieredMenu
        id="hover-menu-button-tiered-menu"
        ref="tieredMenuRef"
        popup
        :dt="tieredMenuStyle"
        :model="menuItems"
        :pt="{
            root: {
                onmouseover: handleTieredMenuMouseOver,
                onmouseleave: handleMouseLeave,
            },
        }"
    >
        <template #item="{ item }">
            <router-link :to="{ name: item.route }" class="p-tieredmenu-item-link text-base-regular-line-height-auto">
                {{ item.label }}
            </router-link>
        </template>
    </TieredMenu>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .hover-menu-button {
        height: 100%;
        padding: main.$spacing-4;
        line-height: 1.2;
        border-radius: 0;
    }
</style>
