<script setup lang="ts">
    import { Navigation } from '@/router/navigation';
    import { medusaAddressToAddressDto } from '@containex/portal-medusa-mapper';
    import AddressDisplay from '@/components/AddressDisplay.vue';
    import { usePriceFormatter } from '@/composables/formatPrice';
    import { useCartAction } from '@/composables/cart';
    import { differenceInWeeks, max } from 'date-fns';
    import { isDefined } from '@containex/common-utils';
    import { computed } from 'vue';
    import { useDateFormatter } from '@/composables/date-format';
    import { useI18n } from 'vue-i18n';
    import { useAsyncTask } from 'vue-concurrency';
    import { PaymentMethod } from '@containex/portal-backend-dto';
    import ProductImage from '@/components/ProductImage.vue';
    import { lineItemsWithoutChilds, sortLineItemsByCreationDate } from '@/util/line-items';
    import { isRentalCart as checkIfIsRentalCart } from '@containex/portal-business-logic';
    import OverlayBadge from 'primevue/overlaybadge';

    const { t } = useI18n();
    const { formatPrice } = usePriceFormatter();
    const { relativeDateFormatter } = useDateFormatter();
    const cartAction = useCartAction();

    const cartTask = useAsyncTask(async () => {
        //eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return await cartAction.retrievePureDatabaseCart(window.history.state.cartId);
    }).perform();

    const cart = computed(() => cartTask.value);
    const isRentalCart = computed(() => (cart.value == null ? false : checkIfIsRentalCart(cart.value)));

    //eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const orderId = window.history.state.orderId;

    const lineItems = computed(() => {
        const items = cart.value?.items ?? [];

        const lineItemsWithoutSpecialCosts = lineItemsWithoutChilds(items);
        const sortedLineItems = sortLineItemsByCreationDate(lineItemsWithoutSpecialCosts);

        return sortedLineItems;
    });

    const earliestPossibleDeliveryDateDisplay = computed(() => {
        const earliestPossibleDeliveryDates =
            cart.value?.lineItemGroups.map((l) => l.estimated_delivery_date_start).filter(isDefined) ?? [];

        if (earliestPossibleDeliveryDates.length === 0) {
            return `: ${t('CART.STEPS.DELIVERY.DELIVERY_DATE_UNKNOWN')}`;
        }

        const difference = differenceInWeeks(max(earliestPossibleDeliveryDates), Date.now());
        return relativeDateFormatter.formatWeeks(difference).value;
    });

    function translatePaymentMethod(paymentMethod?: PaymentMethod): string {
        //eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
        switch (paymentMethod) {
            case PaymentMethod.Prepayment:
                return t('CHECKOUT.PAYMENT.PREPAYMENT.TITLE');
            case PaymentMethod.OnAccount:
                return t('CHECKOUT.PAYMENT.ON_ACCOUNT.TITLE');
            default:
                return t('CHECKOUT.PAYMENT.UNKNOWN');
        }
    }
</script>

<template>
    <div v-if="cartTask.isSuccessful && cart != null" class="order-overview">
        <router-link :to="{ name: Navigation.OrderList }" class="text-xl-bold-line-height-auto header"
            >{{ t('CHECKOUT.FINISH_CHECKOUT_SUCCESS.ORDER_LINK', { number: orderId }) }}
        </router-link>

        <div class="order-grid">
            <div class="row">
                <div>
                    {{
                        t('CHECKOUT.FINISH_CHECKOUT_SUCCESS.PRICE_PRODUCTS', {
                            price: formatPrice(cart.total ?? 0),
                            products: lineItems.length,
                        })
                    }}
                </div>
                <div class="thumbnail-container">
                    <OverlayBadge
                        v-for="lineItem in lineItems"
                        :key="lineItem.id"
                        :value="lineItem.quantity"
                        class="img-container"
                    >
                        <ProductImage
                            :url="lineItem.thumbnail ?? undefined"
                            :alt="lineItem.title ?? ''"
                            class="product-image"
                        />
                    </OverlayBadge>
                </div>
            </div>
            <div class="row">
                <div v-if="!isRentalCart">
                    {{ t('CART.STEPS.DELIVERY.ESTIMATED_DELIVERY') }} {{ earliestPossibleDeliveryDateDisplay }}
                </div>
                <div>
                    <AddressDisplay
                        v-if="cart.shipping_address != null"
                        :address="medusaAddressToAddressDto(cart.shipping_address)"
                    />
                </div>
            </div>
            <div class="row">
                <div>
                    {{ translatePaymentMethod(cart.payment_session?.payment_method) }}
                </div>
                <div>
                    <AddressDisplay
                        v-if="cart.billing_address != null"
                        :address="medusaAddressToAddressDto(cart.billing_address)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main' as *;

    .header {
        color: $color-primary-500;
        text-decoration: none;
    }

    .order-overview {
        display: flex;
        flex-direction: column;
        row-gap: $spacing-5;
        padding: $spacing-6;
        border: 1px solid var(--p-content-border-color);
        border-radius: 4px;
    }

    .order-grid {
        display: grid;
        grid-template-columns: 100%;
        grid-gap: $spacing-8;

        .row {
            display: flex;
            flex-direction: column;
            row-gap: $spacing-5;
        }
    }

    @include for-breakpoint-lg {
        .order-grid {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }

    .thumbnail-container {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: $spacing-5;
    }

    .product-image {
        width: 64px;
        height: 64px;
        object-fit: contain;
        border: 1px solid var(--p-content-border-color);
        border-radius: 4px;
    }

    .img-container {
        width: 64px;
        height: 64px;
    }

    :deep(.p-badge.p-badge) {
        background: $color-background-lightblue;
        color: $color-text;
    }
</style>
