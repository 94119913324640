<script setup lang="ts">
    import Divider from 'primevue/divider';
    import { computed } from 'vue';
    import { useDateFormatter } from '@/composables/date-format';
    import { useI18n } from 'vue-i18n';
    import { useCartQuery } from '@/composables/cart';
    import { getRentalDuration } from '@containex/portal-business-logic';

    const { rentalEnd, rentalStart } = useCartQuery();
    const { dateFormatter } = useDateFormatter();
    const { t } = useI18n();

    const rentalStartFormatted = computed(() =>
        rentalStart.value != null ? dateFormatter.formatDate(rentalStart.value, 'short') : ''
    );
    const rentalEndFormatted = computed(() =>
        rentalEnd.value != null ? dateFormatter.formatDate(rentalEnd.value, 'short') : ''
    );

    const rentalDuration = computed(() => getRentalDuration(rentalStart.value, rentalEnd.value));
</script>

<template>
    <div class="cart-sub-price-container rental-period">
        <div class="cart-price-label">{{ t('RENTAL.PERIOD') }}</div>
        <div v-if="rentalStart == null || rentalEnd == null" class="cart-price-label-rental-missing">
            &nbsp;{{ t('RENTAL.PERIOD_MISSING') }}
        </div>
        <div v-else>{{ rentalStartFormatted }} - {{ rentalEndFormatted }}</div>
    </div>
    <div class="cart-sub-price-container">
        <div class="cart-price-label">{{ t('RENTAL.RENTAL_DAYS') }}</div>
        <div v-if="rentalStart == null || rentalEnd == null" class="cart-price-label-rental-missing">
            &nbsp;{{ t('RENTAL.DAYS_MISSING') }}
        </div>
        <div v-else>{{ rentalDuration }} {{ t('RENTAL.DAYS') }}</div>
    </div>
    <Divider class="divider" />
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .divider {
        margin: 0;
    }

    .cart-sub-price-container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: main.$spacing-3 0;

        &.rental-period {
            padding-bottom: 0;
        }
    }

    .cart-sub-price-container:has(> div.cart-price-label-rental-missing) {
        justify-content: normal;
    }

    .cart-price-label {
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>
