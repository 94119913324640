export const enum Navigation {
    Login = '/login',
    Signup = '/signup',
    SalesProductList = '/sales/products',
    SalesProductListNew = '/sales/products/new',
    SalesProductListUsed = '/sales/products/used',
    RentalProductList = '/rental/products',
    SalesProductDetail = '/sales/products/detail',
    RentalProductDetail = '/rental/products/detail',
    SignupSuccess = '/signup-success',
    SignupSelection = '/signup-selection',
    FastTrackRegistrationSuccess = '/fast-track-registration-success',
    NewRegistrationSuccess = '/new-registration-success',
    SalesCart = '/sales/cart',
    RentalCart = '/rental/cart',
    Landing = '/',
    SalesCheckout = '/sales/cart/checkout',
    RentalCheckout = '/rental/cart/checkout',
    SalesCheckoutSuccess = '/sales/cart/checkout/success',
    RentalCheckoutSuccess = '/rental/cart/checkout/success',
    Users = '/account/users',
    Account = '/account',
    AccountSettings = '/account/settings',
    Employees = '/account/employees',
    ForgotPassword = '/forgot-password',
    ResetPassword = '/reset-password',
    ActivateCustomer = '/activate-customer',
    NewSignupActivateCustomer = '/signup/activate',
    ResetPasswordSuccess = '/reset-password-success',
    ActivateCustomerSuccess = '/signup/activate/success',
    ResetPasswordRequestSuccess = '/reset-password-request-success',
    NotFound = '/not-found',
    OrderList = '/account/orders',
    OrderDetail = '/accounts/orders/detail',
    CtxOrderList = '/account/ctx-orders',
    OfferList = '/account/offers',
    InvoiceList = '/account/invoices',
    Leads = '/account/leads',
    LeadDetails = '/account/leads/detail',
    Changelog = '/changelog',
    Downloads = '/account/downloads',
}
