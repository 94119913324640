<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import Button from 'primevue/button';
    import Card from 'primevue/card';
    import type { CtxProductVariant } from '@containex/portal-backend-api-client';
    import { usePriceFormatter } from '@/composables/formatPrice';
    import { computed, watch } from 'vue';
    import IconButtonWithOverlayPanel from '@/components/IconButtonWithOverlayPanel.vue';
    import ProductAvailability from '@/components/ProductAvailability.vue';
    import DepotLocationWithIcon from '@/components/DepotLocationWithIcon.vue';
    import PriceInformation from '@/components/PriceInformation.vue';
    import ProductStockQuantityDisplay from '@/components/ProductStockQuantityDisplay.vue';
    import { Availability, DepotType } from '@containex/portal-backend-dto';
    import { getAvailabilityForVariantBasedOnCart } from '@/util/variantAvailability';
    import { useCartAction, useCartQuery } from '@/composables/cart';
    import { isDiscountedVariant } from '@/util/variant-discount';
    import ProductCartButtonGroup from './ProductCartButtonGroup.vue';

    const props = defineProps<{
        variant: CtxProductVariant;
        currentRegionId?: string;
        currentZipCode?: string;
    }>();

    const emits = defineEmits<{
        'add-to-cart': [];
        'variant-select': [];
    }>();

    const { t } = useI18n();
    const { formatPrice } = usePriceFormatter();
    const { cart } = useCartQuery();
    const cartAction = useCartAction();

    const productAvailability = computed(() => getAvailabilityForVariantBasedOnCart(props.variant, cart.value, true));
    const productCompletionDate = computed(() => props.variant.depotQuantity.completion_date);
    const productOnDemandCompletionDate = computed(() => props.variant.depotQuantity.on_demand_completion_date);

    const hasPrice = computed(
        () =>
            props.variant.calculated_price != null &&
            props.variant.calculated_price !== 0 &&
            props.currentRegionId != null &&
            props.currentZipCode != null
    );

    const isDiscounted = computed(() => {
        return isDiscountedVariant(props.variant);
    });

    const priceDisplay = computed(() => {
        return hasPrice.value && props.variant.calculated_price != null
            ? formatPrice(props.variant.calculated_price)
            : t('PRODUCT.PRICE_ON_REQUEST');
    });

    const depot = computed(() => props.variant.depotQuantity.depot);

    let isVariantAvailableForCart = computed(() => cartAction.isVariantAddableToCart(props.variant));

    watch(cart, () => {
        isVariantAvailableForCart = computed(() => cartAction.isVariantAddableToCart(props.variant));
    });
</script>
<template>
    <Card class="item">
        <template #content>
            <div class="item-container">
                <div class="item-header">
                    <Button text class="variant-title" :label="variant.title" @click="emits('variant-select')" />
                    <div class="item-header-right">
                        <strong v-if="!isDiscounted" class="text-md-bold-line-height-auto price-display">
                            {{ priceDisplay }}
                        </strong>
                        <div v-else class="price-discount-container">
                            <span class="text-md-bold-line-height-auto price-display-original">{{
                                formatPrice(variant.original_price ?? 0)
                            }}</span>
                            <strong class="text-md-bold-line-height-auto color-discount">{{ priceDisplay }}</strong>
                        </div>
                        <IconButtonWithOverlayPanel v-if="hasPrice" class="icon-button-positioned">
                            <template #icon>
                                <span class="pi pi-info-circle info-icon" />
                            </template>

                            <template #overlay-panel-content>
                                <PriceInformation :depot-type="depot.type" :depot-location="depot.address.city" />
                                <span class="price-information">{{ t('PRODUCT.PRICE_EXCLUDING_TAX') }}</span>
                            </template>
                        </IconButtonWithOverlayPanel>
                    </div>
                </div>
                <div class="content">
                    <div class="product-info">
                        <DepotLocationWithIcon :depot-type="depot.type" :depot-location="depot.address.city" />
                        <ProductStockQuantityDisplay
                            v-if="variant.depotQuantity.depot.type !== DepotType.ProductionSite"
                            :stock-quantity="variant.inventory_quantity ?? 0"
                        />
                        <ProductAvailability
                            :product-available="productAvailability"
                            :completion-date="productCompletionDate"
                            :on-demand-completion-date="productOnDemandCompletionDate"
                        />
                    </div>
                    <div class="other-info">
                        <div v-if="variant.id != null && hasPrice">
                            <ProductCartButtonGroup
                                :variant-id="variant.id"
                                :is-add-to-cart-disabled="
                                    variant.calculated_price == null ||
                                    variant.calculated_price === 0 ||
                                    productAvailability === Availability.NotAvailable ||
                                    !isVariantAvailableForCart
                                "
                                mode="icon-only"
                                @add-to-cart="emits('add-to-cart')"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </Card>
</template>
<style scoped lang="scss">
    @use 'src/styling/main';

    .item-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .item-header {
        display: flex;
        justify-content: space-between;
    }

    .item-header-right {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    .variant-title {
        padding: 0;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:hover {
            background: transparent;
        }
    }

    .icon-button-positioned:hover {
        border: 0 none;
        border-radius: 50%;
        background-color: main.$color-background-bluegray;
    }

    .content {
        display: flex;
        gap: main.$spacing-5;
        align-items: start;
    }

    .product-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: main.$spacing-1;
    }

    .other-info {
        display: flex;
        gap: main.$spacing-5;
        align-items: center;
    }

    .price-display {
        text-align: end;
        white-space: pre;
    }

    .price-discount-container {
        display: flex;
        flex-direction: column;
    }

    .price-display-original {
        font-size: main.$font-size-1;
        text-decoration: line-through;
        color: main.$color-text;
        font-weight: normal;
    }

    .price-information {
        text-align: right;
        font-size: main.$font-size-2;
        color: main.$color-secondary;
    }

    .info-icon {
        color: main.$color-primary-500;
    }

    .item :deep(.p-card-title) {
        display: flex;
        font-size: unset;
        font-weight: unset;
    }

    .item :deep(.p-card-body) {
        padding: main.$spacing-4;
    }

    .item :deep(.p-card-content) {
        padding: 0;
    }
</style>
