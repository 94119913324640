<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import { format } from 'date-fns';
    import Button from 'primevue/button';
    import Divider from 'primevue/divider';
    import { isMobile } from '@/util/breakpoints';
    import { usePriceFormatter } from '@/composables/formatPrice';
    import { useDateFormatter } from '@/composables/date-format';
    import router from '@/router';
    import { Navigation } from '@/router/navigation';
    import OrderHeaderItem from '../components/OrderHeaderItem.vue';
    import Badge from 'primevue/badge';

    export interface ReceiptHeaderItem {
        title: string;
        value: string;
    }

    interface ReceiptNavigation {
        navigation: Navigation;
        text: string;
        id: string;
    }

    interface ReceiptItem {
        quantity: number;
        thumbnail?: string | null | undefined;
    }

    export interface ReceiptAdditionalInformation {
        quantity: number;
        amount: number;
        postalCode?: string;
        countryCode?: string;
        isRental: boolean;
        rentalStart?: Date;
        rentalEnd?: Date;
    }

    const props = defineProps<{
        headerItems: ReceiptHeaderItem[];
        detailsLink?: ReceiptNavigation;
        additionalInformation?: ReceiptAdditionalInformation;
        items: ReceiptItem[];
    }>();

    const { t } = useI18n();
    const { formatPrice } = usePriceFormatter();
    const { dateFormatter } = useDateFormatter();

    async function navigateToReceiptDetails(): Promise<void> {
        if (props.detailsLink != null) {
            await router.push({
                name: props.detailsLink.navigation,
                params: {
                    id: props.detailsLink.id,
                },
            });
        }
    }
</script>
<template>
    <div class="receipt-header">
        <div class="receipt-header-grid">
            <template v-for="headerItem of headerItems" :key="headerItem.title">
                <OrderHeaderItem :heading="headerItem.title" :value="headerItem.value" />
            </template>
        </div>
        <div>
            <Button
                v-if="detailsLink != null"
                outlined
                type="button"
                :label="detailsLink.text"
                @click="navigateToReceiptDetails()"
            />
        </div>
    </div>
    <div class="receipt-content">
        <template v-if="additionalInformation != null">
            <div class="receipt-content-description">
                <span class="receipt-content-text">
                    <i class="pi pi-shopping-cart"></i> {{ additionalInformation.quantity }}
                    {{ t('ACCOUNT.RECEIPT_LIST.ARTICLES') }}:
                    {{ formatPrice(additionalInformation.amount) }}
                </span>
                <template v-if="additionalInformation.countryCode != null && additionalInformation.postalCode != null">
                    <span class="receipt-content-text"
                        ><i class="pi pi-truck"></i>
                        {{
                            additionalInformation.isRental
                                ? t('ACCOUNT.RECEIPT_LIST.RENTAL_POSTAL_CODE')
                                : t('ACCOUNT.RECEIPT_LIST.SALES_POSTAL_CODE')
                        }}: {{ additionalInformation.countryCode.toUpperCase() }}-{{ additionalInformation.postalCode }}
                    </span>
                </template>
                <template v-if="additionalInformation.rentalStart != null && additionalInformation.rentalEnd != null">
                    <span class="receipt-content-text">
                        <i class="pi pi-calendar"></i>
                        {{ t('ACCOUNT.RECEIPT_LIST.RENTAL_TIME') }}
                        {{
                            dateFormatter.formatDateTwoDigits(
                                new Date(format(additionalInformation.rentalStart, 'yyyy-MM-dd'))
                            )
                        }}
                        -
                        {{
                            dateFormatter.formatDateTwoDigits(
                                new Date(format(additionalInformation.rentalEnd, 'yyyy-MM-dd'))
                            )
                        }}
                    </span>
                </template>
            </div>
            <Divider :layout="!isMobile ? 'vertical' : 'horizontal'" />
        </template>
        <div class="receipt-images">
            <template v-for="item of items" :key="item">
                <div class="receipt-image-container">
                    <div v-if="item.quantity > 1" class="receipt-item-quantity">
                        <Badge :value="item.quantity" severity="secondary" />
                    </div>
                    <img v-if="item.thumbnail != null" :src="item.thumbnail" class="receipt-image" />
                    <div v-else class="receipt-image-placeholder">
                        <img src="/assets/images/containex.svg" class="receipt-image" />
                    </div>
                </div>
            </template>
            <template v-if="items.length === 0">
                {{ t('ACCOUNT.RECEIPT_LIST.NO_PRODUCTS') }}
            </template>
        </div>
    </div>
</template>
<style scoped lang="scss">
    @use 'src/styling/main';

    .receipt-header {
        background: main.$color-background-table-header;
        padding: main.$spacing-5;
        border-radius: 6px 6px 0 0;
        border-bottom: 1px solid main.$color-background-lightgray;
        display: flex;
        flex-direction: column;
        gap: main.$spacing-5;
    }

    @include main.for-breakpoint-lg {
        .receipt-header {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }

    .receipt-header-grid {
        display: grid;
        grid-template-columns: 50% calc(50% - main.$spacing-5);
        gap: main.$spacing-5;
    }

    @include main.for-breakpoint-lg {
        .receipt-header-grid {
            grid-template-columns: auto auto auto auto;
            gap: main.$spacing-9;
        }
    }

    .receipt-content {
        padding: main.$spacing-5;
        display: flex;
        flex-direction: column;
        justify-content: left;
        gap: main.$spacing-2;
    }

    @include main.for-breakpoint-lg {
        .receipt-content {
            flex-direction: row;
            align-items: center;
            gap: main.$spacing-5;
        }
    }

    .receipt-content-description {
        align-self: flex-start;
    }

    @include main.for-breakpoint-lg {
        .receipt-content-description {
            width: 40%;
            text-wrap: nowrap;
        }
    }

    .receipt-content-text {
        display: flex;
        gap: main.$spacing-4;
        align-items: center;
    }

    .receipt-images {
        width: 100%;
        display: flex;
        gap: main.$spacing-5;
        flex-wrap: wrap;
    }

    .receipt-image-container {
        position: relative;
        display: flex;
        align-items: center;
        border: 1px solid main.$color-background-lightgray;
        padding: main.$spacing-2;
    }

    .receipt-image {
        max-height: 75px;
        max-width: 75px;
    }

    .receipt-item-quantity {
        position: absolute;
        bottom: main.$spacing-2;
        right: main.$spacing-2;
    }
</style>
