<script setup lang="ts">
    import { LeadState } from '@containex/portal-backend-dto';
    import { computed } from 'vue';
    import { useI18n } from 'vue-i18n';
    import Chip from 'primevue/chip';
    import { getLogger } from '@/logger/logger';
    import { componentStyle } from '@/styling/util/component-style';

    const props = defineProps<{
        state: LeadState;
    }>();

    const { t } = useI18n();
    const logger = getLogger('LeadChip');

    const doneChipDesignTokens = {
        color: '{ color.statusGreenColor }',
        background: '{ color.statusGreenBackground }',
    };

    const inProgressChipDesignTokens = {
        color: '{ color.statusBlueColor }',
        background: '{ color.statusBlueBackground }',
    };

    const newChipDesignTokens = {
        color: '{ color.statusOrangeColor }',
        background: '{ color.statusOrangeBackground }',
    };

    const leadData = computed(() => {
        switch (props.state) {
            case LeadState.New:
                return {
                    label: t('ACCOUNT.LEADS.LEAD_STATE.NEW'),
                    designTokens: newChipDesignTokens,
                };
            case LeadState.InProgress:
                return {
                    label: t('ACCOUNT.LEADS.LEAD_STATE.IN_PROGRESS'),
                    designTokens: inProgressChipDesignTokens,
                };
            case LeadState.Done:
                return {
                    label: t('ACCOUNT.LEADS.LEAD_STATE.DONE'),
                    designTokens: doneChipDesignTokens,
                };
            default:
                //eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                logger.error(`no matching LeadState found for given status: ${props.state}`);
                return {};
        }
    });

    const chipStyle = computed(() =>
        componentStyle({
            paddingX: '0.5rem',
            borderRadius: '6px',
            ...leadData.value.designTokens,
        })
    );
</script>

<template>
    <Chip :dt="chipStyle" :label="leadData.label" />
</template>

<style scoped lang="scss">
    @use '../../styling/main';
</style>
