<script setup lang="ts">
    import RentalCheckoutStepper from '@/checkout/components/rental/RentalCheckoutStepper.vue';
    import CheckoutNavBar from '@/checkout/components/CheckoutNavBar.vue';
    import { useCompanyAction } from '@/composables/company';
    import { useCheckoutAction } from '@/checkout/composables/checkout';
    import { useAsyncTask } from 'vue-concurrency';
    import { useCartQuery } from '@/composables/cart';
    import { useCheckoutCartAction } from '../composables/checkout-cart';
    import router from '@/router';
    import { Navigation } from '@/router/navigation';

    const companyAction = useCompanyAction();
    const checkoutAction = useCheckoutAction();

    const task = useAsyncTask(async () => {
        await companyAction.findCustomerCompany();
        await checkoutAction.initLineItemGroups();
        const { cart } = useCartQuery();
        const checkoutCartAction = useCheckoutCartAction();
        checkoutCartAction.resetCheckoutCart();
        if (cart.value != null) {
            checkoutCartAction.initCheckoutCart(cart.value);
        } else {
            await router.push({ name: Navigation.RentalCart });
        }
    }).perform();
</script>

<template>
    <CheckoutNavBar :is-rental="true" />
    <div class="container">
        <main>
            <RentalCheckoutStepper v-if="task.isFinished" />
        </main>
    </div>
</template>
<style scoped lang="scss">
    @use 'src/styling/main';

    .container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        background: main.$color-background-bluegray;
    }

    main {
        height: 100%;
        padding-bottom: main.$spacing-6;
    }
</style>
