<script setup lang="ts">
    import { CtxOrderItemStatus } from '@containex/portal-backend-dto';
    import { computed } from 'vue';
    import { useI18n } from 'vue-i18n';
    import Chip from 'primevue/chip';
    import { getLogger } from '@/logger/logger';
    import { componentStyle } from '@/styling/util/component-style';

    const props = defineProps<{
        state: CtxOrderItemStatus;
    }>();

    const { t } = useI18n();
    const logger = getLogger('OrderItemStateChip');

    const doneChipDesignTokens = {
        color: '{ color.statusGreenColor }',
        background: '{ color.statusGreenBackground }',
    };

    const inProgressChipDesignTokens = {
        color: '{ color.statusBlueColor }',
        background: '{ color.statusBlueBackground }',
    };

    const data = computed(() => {
        switch (props.state) {
            case CtxOrderItemStatus.Approved:
                return {
                    label: t('ACCOUNT.ORDER_DETAIL.ORDER_ITEM_STATE.APPROVED'),
                };
            case CtxOrderItemStatus.Closed:
                return {
                    label: t('ACCOUNT.ORDER_DETAIL.ORDER_ITEM_STATE.CLOSED'),
                    designTokens: doneChipDesignTokens,
                };
            case CtxOrderItemStatus.InProgress:
                return {
                    label: t('ACCOUNT.ORDER_DETAIL.ORDER_ITEM_STATE.IN_PROGRESS'),
                    designTokens: inProgressChipDesignTokens,
                };
            case CtxOrderItemStatus.Invoiced:
                return {
                    label: t('ACCOUNT.ORDER_DETAIL.ORDER_ITEM_STATE.INVOICED'),
                    designTokens: doneChipDesignTokens,
                };
            case CtxOrderItemStatus.Open:
                return {
                    label: t('ACCOUNT.ORDER_DETAIL.ORDER_ITEM_STATE.OPEN'),
                };
            case CtxOrderItemStatus.Rented:
                return {
                    label: t('ACCOUNT.ORDER_DETAIL.ORDER_ITEM_STATE.RENTED'),
                    designTokens: inProgressChipDesignTokens,
                };
            case CtxOrderItemStatus.Returned:
                return {
                    label: t('ACCOUNT.ORDER_DETAIL.ORDER_ITEM_STATE.RETURNED'),
                    designTokens: doneChipDesignTokens,
                };
            default:
                //eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                logger.error(`no matching LeadState found for given status: ${props.state}`);
                return {};
        }
    });

    const chipStyle = computed(() =>
        componentStyle({
            paddingX: '0.5rem',
            borderRadius: '6px',
            ...data.value.designTokens,
        })
    );
</script>

<template>
    <Chip :dt="chipStyle" :label="data.label" />
</template>

<style scoped lang="scss">
    @use '../../styling/main';
</style>
